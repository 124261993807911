import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiUsers } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { AiOutlineShop } from "react-icons/ai";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { LiaCoinsSolid } from "react-icons/lia";
import { PiPlant } from "react-icons/pi";
import { GoGear } from "react-icons/go";
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill } from "react-icons/bs";

import Welcome from "./Welcome";
import Product from "./Product";
import HumanSupport from "./HumanSupport";
import HumanTeam from "./HumanTeam";
import OfferProduct from "./OfferProduct";
import OfferCompetition from "./OfferCompetition";
import OfferLaunch from "./OfferLaunch";
import OfferMonetization from "./OfferMonetization";
import MarketPosition from "./MarketPosition";
import MarketCharacteristics from "./MarketCharacteristics";
import MarketCompetitors from "./MarketCompetitors";
import StrategyPartnership from "./StrategyPartnership";
import StrategyIP from "./StrategyIP";
import StrategyCommunication from "./StrategyCommunication";
import StrategyGrowth from "./StrategyGrowth";
import InnovationStateOfArt from "./InnovationStateOfArt";
import InnovationRnD from "./InnovationRnD";
import ImpactCse from "./ImpactCse";
import ImpactPotential from "./ImpactPotential";
import PaymentSuccess from "./PaymentSuccess";
import FinanceSituation from "./FinanceSituation";
import FinanceShareholder from "./FinanceShareholder";
import FinanceFunding from "./FinanceFunding";
import FinanceReview from "./FinanceReview";
import FinanceBusinessPlan from "./FinanceBusinessPlan";
import Finish from "./Finish";
import Documents from "./Documents";
import Interview from "./Interview";
import Result from "./Result";

import api from "@/services/api";

const StartUpForm = () => {
  const { id } = useParams();
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;

    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          if (!res.data.valuation_has_paid && (pathname.includes("/form") || pathname.includes("/documents") || pathname.includes("/interview") || pathname.includes("/result")))
            navigation(`product`);
          else if (res.data.valuation_has_paid && pathname.includes("/product")) navigation(`form-human-team`);
          else {
            if (res.data.valuation_step === "FORM" && !pathname.includes("/form")) navigation(`form-human-team`);
            if (res.data.valuation_step === "DOCUMENTS" && !pathname.includes("/documents")) navigation(`documents`);
            if ((res.data.valuation_step === "INTERVIEW" || res.data.valuation_step === "INTERVIEW_SCHEDULED") && !pathname.includes("/interview")) navigation(`interview`);
            if (res.data.valuation_step === "REPORT" && !pathname.includes("/result")) navigation(`result`);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [id, location.pathname]);

  return (
    <div className="w-full flex">
      <Navigation />
      <section className="flex flex-col flex-1 items-center">
        <Routes>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/product" element={<Product />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />

          <Route path="/form-human" element={<Navigate to="../form-human-team" />} />
          <Route path="/form-human-team" element={<HumanTeam />} />
          <Route path="/form-human-support" element={<HumanSupport />} />

          <Route path="/form-offer-product" element={<OfferProduct />} />
          <Route path="/form-offer-competition" element={<OfferCompetition />} />
          <Route path="/form-offer-launch" element={<OfferLaunch />} />
          <Route path="/form-offer-monetization" element={<OfferMonetization />} />

          <Route path="/form-market-position" element={<MarketPosition />} />
          <Route path="/form-market-characteristics" element={<MarketCharacteristics />} />
          <Route path="/form-market-competitors" element={<MarketCompetitors />} />

          <Route path="/form-strategy-partnership" element={<StrategyPartnership />} />
          <Route path="/form-strategy-ip" element={<StrategyIP />} />
          <Route path="/form-strategy-communication" element={<StrategyCommunication />} />
          <Route path="/form-strategy-growth" element={<StrategyGrowth />} />

          <Route path="/form-innovation-state-of-the-art" element={<InnovationStateOfArt />} />
          <Route path="/form-innovation-rnd" element={<InnovationRnD />} />

          <Route path="/form-finance-situation" element={<FinanceSituation />} />
          <Route path="/form-finance-shareholder" element={<FinanceShareholder />} />
          <Route path="/form-finance-funding" element={<FinanceFunding />} />
          <Route path="/form-finance-review" element={<FinanceReview />} />
          <Route path="/form-finance-business-plan" element={<FinanceBusinessPlan />} />

          <Route path="/form-impact-cse" element={<ImpactCse />} />
          <Route path="/form-impact-potential" element={<ImpactPotential />} />

          <Route path="/form-finish" element={<Finish />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/interview" element={<Interview />} />
          <Route path="/result" element={<Result />} />
        </Routes>
      </section>
    </div>
  );
};

const Navigation = () => {
  const [step, setStep] = useState("product");
  const [active, setActive] = useState("human");
  const location = useLocation();
  const { t } = useTranslation();
  const MENU = [
    {
      name: t("index.human"),
      link: "form-human",
      icon: <FiUsers />,
      subMenu: [
        { name: "Team", link: "form-human-team" },
        { name: "Support", link: "form-human-support" },
      ],
    },
    {
      name: t("index.offer"),
      link: "form-offer",
      icon: <MdOutlineLocalOffer />,
      subMenu: [
        { name: "Product", link: "form-offer-product" },
        { name: "Competition", link: "form-offer-competition" },
        { name: "Launch", link: "form-offer-launch" },
        { name: "Monetization", link: "form-offer-monetization" },
      ],
    },
    {
      name: t("index.market"),
      link: "form-market",
      icon: <AiOutlineShop />,
      subMenu: [
        { name: "Position", link: "form-market-position" },
        { name: "Characteristics", link: "form-market-characteristics" },
        { name: "Competitors", link: "form-market-competitors" },
      ],
    },
    {
      name: t("index.strategy"),
      link: "form-strategy",
      icon: <HiOutlineRocketLaunch />,
      subMenu: [
        { name: "Partnership", link: "form-strategy-partnership" },
        { name: "IP", link: "form-strategy-ip" },
        { name: "Communication", link: "form-strategy-communication" },
        { name: "Growth", link: "form-strategy-growth" },
      ],
    },
    {
      name: t("index.innovation"),
      link: "form-innovation",
      icon: <GoGear />,
      subMenu: [
        { name: "State of art", link: "form-innovation-state-of-the-art" },
        { name: "R and D", link: "form-innovation-rnd" },
      ],
    },
    {
      name: t("index.finance"),
      link: "form-finance",
      icon: <LiaCoinsSolid />,
      subMenu: [
        { name: "Situation", link: "form-finance-situation" },
        { name: "Shareholder", link: "form-finance-shareholder" },
        { name: "Funding", link: "form-finance-funding" },
        { name: "Review", link: "form-finance-review" },
        { name: "Business Plan", link: "form-finance-business-plan" },
      ],
    },
    {
      name: "Impact",
      link: "form-impact",
      icon: <PiPlant />,
      subMenu: [
        { name: "CSE", link: "form-impact-cse" },
        { name: "Potential", link: "form-impact-potential" },
      ],
    },
  ];

  useEffect(() => {
    setActive(location.pathname.split("/").pop());
    if (location.pathname.includes("product")) setStep("product");
    if (location.pathname.includes("form")) setStep("form");
    if (location.pathname.includes("documents")) setStep("documents");
    if (location.pathname.includes("interview")) setStep("interview");
    if (location.pathname.includes("result")) setStep("result");
  }, [location]);

  if (step === "product") return null;

  return (
    <ul className="space-y-6">
      <li>
        <div className={`flex items-center ${step === "form" ? "opacity-100" : "opacity-50"}`}>
          <Bs1CircleFill className="mr-3" />
          <h1 className="font-semibold">Formulaire</h1>
        </div>
      </li>
      {step === "form" &&
        MENU.map((item) => (
          <li key={item.name} className="pl-4">
            <NavLink menu={item} active={active} />
          </li>
        ))}
      <li>
        <div className={`flex items-center ${step === "documents" ? "opacity-100" : "opacity-50"}`}>
          <Bs2CircleFill className="mr-3" />
          <h1 className="font-semibold">Documents</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "interview" ? "opacity-100" : "opacity-50"}`}>
          <Bs3CircleFill className="mr-3" />
          <h1 className="font-semibold">Entretien</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "result" ? "opacity-100" : "opacity-50"}`}>
          <Bs4CircleFill className="mr-3" />
          <h1 className="font-semibold">Résultat</h1>
        </div>
      </li>
    </ul>
  );
};

const NavLink = ({ menu, active }) => {
  const [open, setOpen] = useState(menu.subMenu ? menu.subMenu.some((subMenu) => subMenu.link === active) : false);

  useEffect(() => {
    if (menu.subMenu) setOpen(menu.subMenu.some((subMenu) => subMenu.link === active));
  }, [active]);

  if (menu.subMenu) {
    return (
      <div>
        <button className={`hover:text-primary hover:underline flex items-center ${open ? "text-primary" : ""}`} onClick={() => setOpen(!open)}>
          {menu.icon && <span className="mr-2">{menu.icon}</span>}
          {menu.name}
        </button>
        {open && (
          <ul className="ml-12 space-y-4 pt-4">
            {menu.subMenu.map((subMenu) => (
              <li key={subMenu.name}>
                <Link to={`${subMenu.link}`} className={`hover:text-primary hover:underline text-sm ${active === subMenu.link ? "text-primary underline" : ""}`}>
                  {subMenu.name}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
  return (
    <Link to={menu.link} className={`text-left flex items-center hover:text-primary hover:underline ${active === menu.link ? "text-primary underline" : ""}`}>
      {menu.icon && <span className="mr-2">{menu.icon}</span>}
      {menu.name}
    </Link>
  );
};

export default StartUpForm;
