import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { IoIosClose } from "react-icons/io";

import MultiSelect from "@/components/MultiSelect";
// import CurrencyInput from "react-currency-input-field";

import Pagination from "@/components/Pagination";
import toast from "react-hot-toast";
import api from "@/services/api";

import { INVESTOR_BUSINESS_MODELS, INVESTOR_REGIONS, INVESTOR_SECTORS_PME, BUYER_MATURITIES, BUYER_TYPES } from "../../constants/investor";

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [investors, setInvestors] = useState([]);
  const [total, setTotal] = useState(0);

  const [filters, setFilters] = useState({
    buyer_types: [],
    buyer_maturities: searchParams.get("maturities") ? searchParams.getAll("maturities") : [],
    regions: searchParams.get("regions") ? searchParams.getAll("regions") : [],
    businesses: searchParams.get("businesses") ? searchParams.getAll("businesses") : [],
    sectors: searchParams.get("sectors") ? searchParams.getAll("sectors") : [],
    searchTerm: searchParams.get("searchTerm") || "",
    page: 1,
    limit: 51,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      const fetchData = async () => {
        try {
          const query = {};

          if (filters.buyer_types.length) query.buyer_types = filters.buyer_types;
          if (filters.buyer_maturities.length) query.buyer_maturities = filters.buyer_maturities;
          if (filters.regions.length) query.regions = filters.regions;
          if (filters.businesses.length) query.businesses = filters.businesses;
          if (filters.sectors.length) query.sectors = filters.sectors;
          if (filters.searchTerm) query.buyer_title = filters.searchTerm;
          if (filters.page) query.skip = (filters.page - 1) * filters.limit;
          if (filters.limit) query.limit = filters.limit;

          setSearchParams(query);

          query.annonce_type = "buyer";
          query.annonce_status = "ACCEPTED";

          const res = await api.post("/investor/search", query);
          if (res.ok) {
            setInvestors(res.data);
            setTotal(res.total);
          } else {
            toast.error("Une erreur est survenue");
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }, 300);

    return () => clearTimeout(timeout);
  }, [filters]);

  return (
    <section className="p-8 space-y-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Acquéreurs</h1>
      </header>
      <div className="space-y-2">
        <p className="text-base text-gray-500">Découvrez les acquéreurs</p>
        <input
          type="text"
          placeholder="Rechercher un acquéreur"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={filters.searchTerm}
          onChange={(event) => setFilters((prevFilters) => ({ ...prevFilters, searchTerm: event.target.value }))}
        />
      </div>
      <div className="flex gap-5 flex-wrap">
        <div className="flex-1">
          <MultiSelect
            options={BUYER_TYPES}
            value={filters.buyer_types}
            onChange={(value) => setFilters((prevFilters) => ({ ...prevFilters, buyer_types: value }))}
            placeholder="Type d'acquéreur"
          />
        </div>
        <div className="flex-1">
          <MultiSelect
            options={BUYER_MATURITIES}
            value={filters.buyer_maturities}
            onChange={(value) => setFilters((prevFilters) => ({ ...prevFilters, buyer_maturities: value }))}
            placeholder="Maturité"
          />
        </div>
        <div className="flex-1">
          <MultiSelect
            options={INVESTOR_REGIONS}
            value={filters.regions}
            onChange={(value) => setFilters((prevFilters) => ({ ...prevFilters, regions: value }))}
            placeholder="Zone géographique"
          />
        </div>
        <div className="flex-1">
          <MultiSelect
            options={INVESTOR_BUSINESS_MODELS}
            value={filters.businesses}
            onChange={(value) => setFilters((prevFilters) => ({ ...prevFilters, businesses: value }))}
            placeholder="Modèle économique"
          />
        </div>
        <div className="flex-1">
          <MultiSelect
            options={INVESTOR_SECTORS_PME}
            value={filters.sectors}
            onChange={(value) => setFilters((prevFilters) => ({ ...prevFilters, sectors: value }))}
            placeholder="Secteur"
          />
        </div>
      </div>

      {Object.values(filters).some((value) => value.length > 0) && (
        <div className="flex justify-end">
          <button
            className="primary-button text-xs px-2 h-8"
            onClick={() => {
              setFilters({
                buyer_types: [],
                buyer_maturities: [],
                regions: [],
                businesses: [],
                sectors: [],
                searchTerm: "",
                page: 1,
                limit: 51,
              });
            }}
          >
            <IoIosClose className="mr-2" />
            Réinitialiser
          </button>
        </div>
      )}

      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
      <div className="grid grid-cols-3 gap-3">
        {investors.map((investor, index) => (
          <BuyerCard key={index} data={investor} />
        ))}
      </div>
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </section>
  );
};

const BuyerCard = ({ data }) => {
  return (
    <Link to={`/buyers/${data._id}`} className="w-120 h-60 border border-secondary rounded-xl p-5 truncate cursor-pointer hover:border-primary">
      <div className="flex flex-col justify-around items-center h-full">
        <h3 className="text-xl font-semibold mb-2">{data.buyer_title}</h3>
        <div className="flex items-center justify-start h-full w-full space-x-3">
          <div className="w-[30%]">{data.logo && <img src={data.logo} alt={`${data.structure_name}'s profile`} className="w-24 h-24 rounded-md object-cover" />}</div>
          <div className="flex flex-col items-start justify-between">
            <div className="flex flex-col space-y-1 gap-1">
              <div className="flex flex-wrap items-center gap-x-1 gap-y-1">
                <p className="text-xs">Type</p>

                {data.types.slice(0, 2).map((item, index) => (
                  <span key={index} className="p-1 rounded bg-yellow-200 text-black text-xs">
                    {item}
                  </span>
                ))}
                {data.types.length > 3 && <span className="p-1 rounded bg-yellow-200 text-black text-xs">+{data.types.length - 2}</span>}
              </div>
              <div className="flex flex-wrap items-center gap-x-1 gap-y-1">
                <p className="text-xs">Maturité</p>

                {data.maturities.slice(0, 2).map((item, index) => (
                  <span key={index} className="p-1 rounded bg-blue-200 text-black text-xs">
                    {item}
                  </span>
                ))}
                {data.maturities.length > 2 && <span className="p-1 rounded bg-blue-200 text-black text-xs">+{data.maturities.length - 2}</span>}
              </div>
              <div className="flex flex-wrap items-center gap-x-1 gap-y-1">
                <p className="text-xs">Régions</p>

                {data.regions.slice(0, 2).map((item, index) => (
                  <span key={index} className="p-1 rounded bg-green-200 text-black text-xs">
                    {item}
                  </span>
                ))}
                {data.regions.length > 2 && <span className="p-1 rounded bg-green-200 text-black text-xs">+{data.regions.length - 2}</span>}
              </div>
              <div className="flex flex-wrap items-center gap-x-1 gap-y-1">
                <p className="text-xs">Business</p>

                {data.businesses.slice(0, 2).map((item, index) => (
                  <span key={index} className="p-1 rounded bg-purple-200 text-black text-xs">
                    {item}
                  </span>
                ))}

                {data.businesses.length > 2 && <span className="p-1 rounded bg-purple-200 text-black text-xs">+{data.businesses.length - 2}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default List;
