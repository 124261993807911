import React from "react";
import { Link } from "react-router-dom";

export const DcfCard = () => {
  return (
    <Link to="?modal=dcf" className="border border-gray-200 rounded-xl p-4">
      <h3 className="text-sm  font-semibold mb-4">Méthode DCF</h3>
      <div className="flex flex-col items-center p-4 gap-2">
        <span className="text-xl font-semibold">{Math.round(0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
      </div>
    </Link>
  );
};

export const EbitdaCard = () => {
  return (
    <Link to="?modal=ebitda" className="border border-gray-200 rounded-xl p-4">
      <h3 className="text-sm font-semibold mb-4">Méthode EBE</h3>
      <div className="flex justify-center p-4">
        <span className="text-xl font-semibold">{Math.round(  0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
      </div>
    </Link>
  );
};

export const EbitCard = () => {
  return (
    <Link to="?modal=ebit" className="border border-gray-200 rounded-xl p-4">
      <h3 className="text-sm font-semibold mb-4">Méthode EBIT</h3>
      <div className="flex justify-center p-4">
        <span className="text-xl font-semibold">{Math.round(  0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
      </div>
    </Link>
  );
};

export const FiscaleCard = () => {
  return (
    // <Link to="?modal=fiscale" className="border border-gray-200 rounded-xl p-4">
    <div to="?modal=fiscale" className="border border-gray-200  rounded-xl p-4">
      <h3 className="text-sm font-semibold mb-4">Méthode fiscale</h3>
      <div className="flex justify-center p-4">
        <span className="text-xl font-semibold">{Math.round(  0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
      </div>
    </div>
    // </Link>
  );
};

export const ProductivityCard = () => {
  return (
    <Link to="?modal=productivity" className="border border-gray-200 rounded-xl p-4">
      <h3 className="text-sm font-semibold mb-4">Méthode valeur de productivité</h3>
      <div className="flex justify-center p-4">
        <span className="text-xl font-semibold">{Math.round(  0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
      </div>
    </Link>
  );
};

export const NetIncomeAverageCard = () => {
  return (
    <Link to="?modal=net" className="border border-gray-200 rounded-xl p-4">
      <h3 className="text-sm font-semibold mb-4">Méthode capitalisation du bénéfice net moyen</h3>
      <div className="flex justify-center p-4">
        <span className="text-xl font-semibold">{Math.round(  0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
      </div>
    </Link>
  );
};

export const CashFlowAndCAFCard = () => {
  return (
    <Link to="?modal=caf" className="border border-gray-200 rounded-xl p-4">
      <h3 className="text-sm font-semibold mb-4">Méthode capitalisation de la CAF + trésorerie</h3>
      <div className="flex justify-center p-4">
        <span className="text-xl font-semibold">{Math.round(  0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
      </div>
    </Link>
  );
};

export const MbaAverageCard = () => {
  return (
    <Link to="?modal=mba" className="border border-gray-200 rounded-xl p-4">
      <h3 className="text-sm font-semibold mb-4">Méthode de la capitalisation de la MBA moyenne</h3>
      <div className="flex justify-center p-4">
        <span className="text-xl font-semibold">{Math.round(  0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</span>
      </div>
    </Link>
  );
};
