import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { RiDeleteBin4Line } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal";
import FundraserModal from "./components/FundraserModal";

const FinanceFunding = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [elementIndex, setElementIndex] = useState({});

  useEffect(() => {
    fetchQuestions();
    fetchData();
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const fetchQuestions = async () => {
    try {
      const res = await api.post("/question/search", { limit: 300, startup: true });
      if (res.ok) {
        setQuestions(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      // if (res.ok) {
      //   toast.success("Company updated successfully");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = async (v) => {
    setValues((prev) => ({
      ...prev,
      fundrasers: prev.fundrasers.map((fundraser, i) => (i === elementIndex ? v : fundraser)),
    }));
    setIsOpen(true);
  };

  const handleDelete = async (l, index) => {
    setValues((prev) => ({
      ...prev,
      fundrasers: prev.fundrasers.filter((_, i) => i !== index),
    }));
  };

  const handleAdd = async () => {
    const newFundraser = { leader: "" };
    setValues((prev) => ({
      ...prev,
      fundrasers: [...prev.fundrasers, newFundraser],
    }));
    setElementIndex(values.fundrasers.length);
    setIsOpen(true);
  };

  if (!values) return <Loader />;

  const total =
    (Number(values?.funds_usage_recruitment) || 0) +
    (Number(values?.funds_usage_operational_costs) || 0) +
    (Number(values?.funds_usage_rnd) || 0) +
    (Number(values?.funds_usage_communication) || 0) +
    (Number(values?.funds_usage_managers_salary) || 0) +
    (Number(values?.funds_usage_other) || 0);

  return (
    <div className="w-full space-y-8 px-40">
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.have_you_recevied_non_repavable_grants")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="repavable_grants_yes"
              name="has_received_non_repavable_grants"
              onChange={() => setValues((prev) => ({ ...prev, has_received_non_repavable_grants: true }))}
              checked={values?.has_received_non_repavable_grants === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="repavable_grants_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="repavable_grants_no"
              name="has_received_non_repavable_grants"
              onChange={() => setValues((prev) => ({ ...prev, has_received_non_repavable_grants: false }))}
              checked={values?.has_received_non_repavable_grants === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="repavable_grants_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>
      {values.has_received_non_repavable_grants && (
        <>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("lable.total_amount_of_subsidies_you_have_received")}
              <span className="ml-2 text-red-500">*</span>
            </label>
            <div className="flex gap-2 input justify-between items-center">
              <input
                className=" w-[90%] focus:outline-none "
                placeholder={t("placeholder_enter")}
                value={values?.total_amount_subsidies_received}
                onChange={(e) => setValues((prev) => ({ ...prev, total_amount_subsidies_received: e.target.value }))}
              />
              <span className="px-6 border-l"> € </span>
            </div>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("lable.describe_the_grants_recevied")}
              <span className="ml-2 text-red-500">*</span>
            </label>
            <textarea
              className="input min-h-32"
              placeholder={t("placeholder_enter")}
              value={values?.grants_description || ""}
              onChange={(e) => setValues((prev) => ({ ...prev, grants_description: e.target.value }))}
            />
          </div>
        </>
      )}

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.are_u_looking_for_funds")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="funds_yes"
              name="is_looking_for_funds"
              onChange={() => setValues((prev) => ({ ...prev, is_looking_for_funds: true }))}
              checked={values?.is_looking_for_funds === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="funds_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="funds_no"
              name="is_looking_for_funds"
              onChange={() => setValues((prev) => ({ ...prev, is_looking_for_funds: false }))}
              checked={values?.is_looking_for_funds === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="funds_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.amounts_of_funds_need_to_cover_for_next_18_months")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <input
            className=" w-[90%] focus:outline-none "
            placeholder={t("placeholder_enter")}
            value={values?.funds_needed}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_needed: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.targeted_dilution_level")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <input
            className=" w-[90%] focus:outline-none "
            placeholder={t("placeholder_enter")}
            value={values?.dilution_target}
            onChange={(e) => setValues((prev) => ({ ...prev, dilution_target: e.target.value }))}
          />
          <span className="px-6 border-l"> % </span>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.intended_use_of_funds")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex flex-col gap-2">
          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p>{t("lable.recruit")}</p>
            <div className="flex gap-2 items-center">
              <input
                type="number"
                className="border-l px-3 appearance-none focus:outline-none  py-3 text-gray-700 leading-tight w-16"
                value={values?.funds_usage_recruitment || 0}
                onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_recruitment: Number(e.target.value) }))}
              />
              <span>%</span>
            </div>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p>{t("lable.operational_costs")}</p>
            <div className="flex gap-2 items-center">
              <input
                type="number"
                className="border-l px-3 appearance-none focus:outline-none  py-3 text-gray-700 leading-tight w-16"
                value={values?.funds_usage_operational_costs || 0}
                onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_operational_costs: Number(e.target.value) }))}
              />
              <span>%</span>
            </div>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p>{t("lable.conducting")}</p>{" "}
            <div className="flex gap-2 items-center">
              <input
                type="number"
                className="border-l px-3 appearance-none focus:outline-none  py-3 text-gray-700 leading-tight w-16"
                value={values?.funds_usage_rnd || 0}
                onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_rnd: Number(e.target.value) }))}
              />
              <span>%</span>
            </div>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p>{t("lable.operations")}</p>{" "}
            <div className="flex gap-2 items-center">
              <input
                type="number"
                className="border-l px-3 appearance-none focus:outline-none  py-3 text-gray-700 leading-tight w-16"
                value={values?.funds_usage_communication || 0}
                onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_communication: Number(e.target.value) }))}
              />
              <span>%</span>
            </div>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p>{t("lable.managers")}</p>
            <div className="flex gap-2 items-center">
              <input
                type="number"
                className="border-l px-3 appearance-none focus:outline-none  py-3 text-gray-700 leading-tight w-16"
                value={values?.funds_usage_managers_salary || 0}
                onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_managers_salary: Number(e.target.value) }))}
              />
              <span>%</span>
            </div>
          </div>
          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p>{t("lable.other_uses")}</p>
            <div className="flex gap-2 items-center">
              <input
                type="number"
                className="border-l px-3 appearance-none focus:outline-none  py-3 text-gray-700 leading-tight w-16"
                value={values?.funds_usage_other || 0}
                onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_other: Number(e.target.value) }))}
              />
              <span>%</span>
            </div>
          </div>

          <div className="flex justify-between items-center mt-3">
            <p>{t("lable.total")}</p> <div>{total} % / 100%</div>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.when_finalize_the_fundraising")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className="input"
          placeholder={t("placeholder_date")}
          value={values?.fundraising_finalized_at || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, fundraising_finalized_at: e.target.value }))}
        />
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.percentage_of_share_you_are_willing_to_diverse_for_fundraising")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <input
            className=" w-[90%] focus:outline-none "
            placeholder={t("placeholder_enter")}
            value={values?.share_percentage_divest}
            onChange={(e) => setValues((prev) => ({ ...prev, share_percentage_divest: e.target.value }))}
          />
          <span className="px-6 border-l"> % </span>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.have_you_raised_funds_from_pro_in_past")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="raised_funds_yes"
              name="has_raised_funds_in_past"
              onChange={() => setValues((prev) => ({ ...prev, has_raised_funds_in_past: true }))}
              checked={values?.has_raised_funds_in_past === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="raised_funds_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="raised_funds_no"
              name="has_raised_funds_in_past"
              onChange={() => setValues((prev) => ({ ...prev, has_raised_funds_in_past: false }))}
              checked={values?.has_raised_funds_in_past === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="raised_funds_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <p className="text-lg mb-5 font-semibold">{t("lable.list_of_previous_fundraising")}</p>
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.round_type")}</label>

        <div className="flex flex-col gap-2">
          {values?.fundrasers.length !== 0 &&
            values?.fundrasers?.map((fund, index) => (
              <div
                key={index}
                className="appearance-none py-1  focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center"
              >
                <p>{fund?.round_type}</p>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setElementIndex(index);
                      setIsOpen(true);
                    }}
                  >
                    <MdEdit className="w-10 p-2 h-10 border rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-50" />
                  </button>
                  <button onClick={() => handleDelete(fund, index)}>
                    <RiDeleteBin4Line className="w-10 p-2 h-10 border rounded-md flex justify-center items-center  cursor-pointer hover:bg-gray-50" />
                  </button>
                </div>
              </div>
            ))}

          <button className="w-full bg-gray-200 hover:bg-gray-300 py-2 flex mt-5 justify-center items-center gap-3 rounded" onClick={() => handleAdd()}>
            Ajouter
            <FaPlus />
          </button>

          <Modal
            show={isOpen}
            onClose={() => {
              setIsOpen(false);
              setElementIndex(null);
            }}
          >
            <FundraserModal setOpen={setIsOpen} fundraser={values?.fundrasers[elementIndex]} setFundraser={(v) => handleEdit(v)} questions={questions} />
          </Modal>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.have_leaders_already_raised_funds_in_prior_experiences")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="prior_experiences_yes"
              name="prior_fundraising_experience"
              onChange={() => setValues((prev) => ({ ...prev, prior_fundraising_experience: true }))}
              checked={values?.prior_fundraising_experience === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="prior_experiences_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="prior_experiences_no"
              name="prior_fundraising_experience"
              onChange={() => setValues((prev) => ({ ...prev, prior_fundraising_experience: false }))}
              checked={values?.prior_fundraising_experience === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="prior_experiences_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.have_you_ever_been_valued")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="valued_yes"
              name="has_been_valued"
              onChange={() => setValues((prev) => ({ ...prev, has_been_valued: true }))}
              checked={values?.has_been_valued === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="valued_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="valued_no"
              name="has_been_valued"
              onChange={() => setValues((prev) => ({ ...prev, has_been_valued: false }))}
              checked={values?.has_been_valued === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="valued_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>
      {values.has_been_valued && (
        <>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("lable.last_valuation")}
              <span className="ml-2 text-red-500">*</span>
            </label>
            <div className="flex gap-2 input justify-between items-center">
              <input
                className=" w-[90%] focus:outline-none "
                placeholder={t("placeholder_enter")}
                value={values?.last_valuation}
                onChange={(e) => setValues((prev) => ({ ...prev, last_valuation: e.target.value }))}
              />
              <span className="px-6 border-l"> € </span>
            </div>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("lable.when_was_last_valuation")} <span className="ml-2 text-red-500">*</span>
            </label>
            <input
              className="input"
              placeholder={t("placeholder_date")}
              value={values?.last_valuation_date || ""}
              onChange={(e) => setValues((prev) => ({ ...prev, last_valuation_date: e.target.value }))}
            />
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("lable.context_and_who_conducted_it")} <span className="ml-2 text-red-500">*</span>
            </label>
            <textarea
              className="input min-h-32"
              placeholder={t("placeholder_enter")}
              value={values?.last_valuation_context || ""}
              onChange={(e) => setValues((prev) => ({ ...prev, last_valuation_context: e.target.value }))}
            />
          </div>
        </>
      )}
      <div className="flex justify-between w-full">
        <Link to={`../form-finance-shareholder`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <Link to={`../form-finance-review`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default FinanceFunding;
