import React, { useState, useEffect } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";

import api from "@/services/api";
import useStore from "@/services/store";

const Signup = () => {
  const navigate = useNavigate();
  const [seePassword, setSeePassword] = useState(false);
  const { setUser: setUserStore } = useStore();
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    agree: false,
  });

  const handleSubmit = async (e) => {
    try {
      const res = await api.post(`/user/signup`, user);
      if (res.ok) {
        api.setToken(res.data.token);
        setUserStore(res.data.user);
        navigate("/home/index");
      } else if (res.code === "RESSOURCE_ALREADY_EXISTS") {
        setErrors({ ...errors, email: "Cette adresse email est déjà utilisée" });
      } else if (res.code === "PASSWORD_NOT_VALIDATED") {
        setErrors({ ...errors, password: "Le mot de passe doit faire entre 6 et 100 caractères et contenir au moins 1 lettre" });
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="w-full pb-20">
      <header className="w-full mb-8">
        <h1 className="text-3xl text-center font-semibold text-black mb-2">Inscrivez-vous</h1>
        <p className="text-base text-center text-gray-500">pour accéder à Linkera</p>
      </header>
      <div className="w-full space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="first_name">
              Prénom
            </label>
            <input className="input" name="first_name" value={user.first_name} onChange={(e) => setUser({ ...user, first_name: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="last_name">
              Nom
            </label>
            <input className="input" name="last_name" value={user.last_name} onChange={(e) => setUser({ ...user, last_name: e.target.value })} />
          </div>
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="organization-email">
            E-mail
          </label>
          <input
            type="email"
            className="input"
            name="organization-email"
            value={user.email}
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
              setErrors({ ...errors, email: "" });
            }}
          />
          {errors.email && <p className="text-xs text-red-700">{errors.email}</p>}
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="password">
            Mot de passe
          </label>
          <div className="relative">
            <input
              type={seePassword ? "text" : "password"}
              name="password"
              className="input"
              value={user.password}
              onChange={(e) => {
                setUser({ ...user, password: e.target.value });
                setErrors({ ...errors, password: "" });
              }}
            />
            <div className="absolute top-1/2 -translate-y-1/2 right-4">
              {seePassword ? (
                <EyeSlashIcon className="w-6 h-6 text-primary cursor-pointer" onClick={() => setSeePassword(false)} />
              ) : (
                <EyeIcon className="w-6 h-6 text-primary cursor-pointer" onClick={() => setSeePassword(true)} />
              )}
            </div>
          </div>
          {errors.password && <p className="text-xs text-red-700">{errors.password}</p>}
        </div>
        <label className="flex items-center cursor-pointer text-primary-gray text-sm">
          <input type="checkbox" checked={user.agree} className="!w-auto !p-2.5 !rounded mr-3" onChange={(e) => setUser({ ...user, agree: e.target.checked })} />
          <span>En cochant cette case, j'accepte les conditions générales d'utilisation</span>
        </label>

        <button
          type="button"
          disabled={Object.values(user).some((value) => !value) || Object.values(errors).some((error) => error)}
          className="primary-button w-full !mt-10"
          onClick={handleSubmit}
        >
          Continuer
        </button>
      </div>
      <div className="flex items-center gap-x-1 mt-4 text-left mr-auto">
        <p className="leading-6">Vous avez déjà un compte ?</p>
        <Link to="/login" className="font-medium text-primary">
          Se connecter
        </Link>
      </div>
    </div>
  );
};

export default Signup;
