import React from "react";
import { useTranslation } from "react-i18next";

const STARTING_YEAR = 2020;

const KnownBPTable = ({ values, setValues, computedValues }) => {
  const { t } = useTranslation();

  const KEYS = ["turnover", "ebitda", "operating_income", "net_income"];
  const KEYS_LABEL = {
    turnover: t("business_plan.turnover.title"),
    ebitda: t("business_plan.ebitda.title"),
    operating_income: t("business_plan.operating_income.title"),
    net_income: t("business_plan.net_income.title"),
  };

  const years = Array.from(Array(11).keys()).map((i) => STARTING_YEAR + i);

  const parseToNumber = (value) => {
    if (value === "") return 0;
    if (value.includes(",") && !value.includes(".")) value = value.replace(",", ".");
    if (value.includes(" ")) value = value.replace(" ", "");
    return Number(value);
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full table-fixed">
        <thead>
          <tr>
            <th colSpan="1" className="border border-secondary text-gray-700 font-normal text-sm"></th>
            <th colSpan="4" className="border border-secondary p-2  text-left text-gray-700 font-normal text-sm">
              Past balance sheet
            </th>
            <th colSpan="7" className="border border-secondary p-2 text-left text-gray-700 font-normal text-sm">
              Predicted balance sheet
            </th>
          </tr>
          <tr className="text-sm">
            <th className="border border-secondary p-2">Year</th>
            {years.map((year) => (
              <th key={year} className="border p-2">
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {KEYS.map((key) => (
            <tr key={key}>
              <td className="border border-secondary p-2 text-xs w-36">{KEYS_LABEL[key]}</td>
              {years.map((year, index) => (
                <td key={year} className={`border border-secondary text-xs px-2 ${1 < index && index < 5 ? "bg-orange-100" : ""} `}>
                  <input
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values[`${key}_${index}`] || 0}
                    onChange={(e) => setValues({ ...values, [`${key}_${index}`]: parseToNumber(e.target.value) })}
                  />
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td className="border border-secondary p-2 text-xs w-24 bg-white">Excédent Brut d'Exploitation (EBE)</td>
            {years.map((year, index) => (
              <td key={year} className="bg-gray-100 text-xs px-2 text-right border">
                {(computedValues.free_cash_flow[index] || 0).toFixed(2)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default KnownBPTable;
