import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiDeleteBin4Line } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

import api from "@/services/api";
import Modal from "@/components/Modal";
import Loader from "@/components/Loader";
import ExecutiveModal from "./components/ExecutiveModal";

const HumanTeam = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [elementIndex, setElementIndex] = useState({});

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const res = await api.post("/question/search", { limit: 300, startup: true });
        if (res.ok) {
          setQuestions(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchQuestions();
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          if (!res.data.plan) res.data.plan = "certified";
          setValues(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (!values) return;
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      // if (res.ok) {
      //   toast.success("Company updated successfully");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = async (v) => {
    setValues((prev) => ({
      ...prev,
      leaders: prev.leaders.map((leader, i) => (i === elementIndex ? v : leader)),
    }));
    setIsOpen(true);
  };

  const handleDelete = async (l, index) => {
    setValues((prev) => ({
      ...prev,
      leaders: prev.leaders.filter((_, i) => i !== index),
    }));
  };

  const handleAdd = async () => {
    const newLeader = { leader: "" };
    setValues((prev) => ({
      ...prev,
      leaders: [...prev.leaders, newLeader],
    }));
    setElementIndex(values.leaders.length);
    setIsOpen(true);
  };


  const total =
    (values?.team_distribution_marketing || 0) +
    (values?.team_distribution_sales || 0) +
    (values?.team_distribution_engineering || 0) +
    (values?.team_distribution_dev || 0) +
    (values?.team_distribution_product || 0) +
    (values?.team_distribution_finance || 0);

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.how_many_people")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className="input"
          placeholder={t("placeholder_enter")}
          value={values?.founder_count}
          onChange={(e) => setValues((prev) => ({ ...prev, founder_count: e.target.value }))}
        />
      </div>

      <div className="mb-6">
        <p className="text-lg mb-5 font-semibold">{t("lable.executive")}</p>
        <label className="block text-gray-700 text-sm font-bold mb-2">{t("lable.leader")}</label>

        <div className="flex flex-col gap-2">
          {values?.leaders.length !== 0 &&
            values?.leaders?.map((l, index) => (
              <div
                key={index}
                className="appearance-none py-1  focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center"
              >
                <p>{l.leader}</p>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setElementIndex(index);
                      setIsOpen(true);
                    }}
                  >
                    <MdEdit className="w-10 p-2 h-10 border rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-50" />
                  </button>
                  <button onClick={() => handleDelete(l, index)}>
                    <RiDeleteBin4Line className="w-10 p-2 h-10 border rounded-md flex justify-center items-center  cursor-pointer hover:bg-gray-50" />
                  </button>
                </div>
              </div>
            ))}

          <button className="w-full bg-gray-200 hover:bg-gray-300 py-2 flex mt-5 justify-center items-center gap-3 rounded" onClick={() => handleAdd()}>
            Ajouter
            <FaPlus />
          </button>
          <Modal
            show={isOpen}
            onClose={() => {
              setIsOpen(false);
              setElementIndex(null);
            }}
          >
            <ExecutiveModal setOpen={setIsOpen} leader={values?.leaders[elementIndex]} setLeader={(v) => handleEdit(v)} questions={questions} />
          </Modal>
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.how_many_full_time_employee")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className="input"
          placeholder={t("placeholder_enter")}
          value={values?.employee_count}
          onChange={(e) => setValues((prev) => ({ ...prev, employee_count: e.target.value }))}
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.how_many_trainee")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className="input"
          placeholder={t("placeholder_enter")}
          value={values?.trainee_count}
          onChange={(e) => setValues((prev) => ({ ...prev, trainee_count: e.target.value }))}
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.how_many_freelancers")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className="input"
          placeholder={t("placeholder_enter")}
          value={values?.freelancers_count}
          onChange={(e) => setValues((prev) => ({ ...prev, freelancers_count: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.internal_team_perc")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="space-y-2">
          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.marketing")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_marketing || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_marketing: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.sales")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_sales || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_sales: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.engineering")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_engineering || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_engineering: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.code")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_dev || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_dev: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.product")}</p>

            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_product || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_product: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>
          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.finance")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_finance || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_finance: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="flex justify-between items-center mt-3">
            <p>{t("lable.total")}</p> <div>{total} %</div>
          </div>
        </div>
      </div>

      <div className="flex justify-end w-full">
        <Link to={`../form-human-support`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default HumanTeam;
