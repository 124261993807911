import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import Loader from "@/components/Loader";
import api from "@/services/api";
import useStore from "@/services/store";

const PLAN = {
  ACCELERATED: <div className="bg-green-200 rounded p-1 text-xs">Accéléré</div>,
  CERTIFIED: <div className="bg-blue-200 rounded p-1 text-xs">Certifié</div>,
};

const STATUS = {
  PENDING: <div className="border border-yellow-500 rounded p-1 text-yellow-500">En attente</div>,
  IN_PROGRESS: <div className="border border-blue-500 rounded p-1 text-blue-500">En cours</div>,
  FINISHED: <div className="border border-green-500 rounded p-1 text-green-500">Terminé</div>,
};
const STEP_LABEL = {
  PRODUCT: "Selection du produit",
  FORM: "Remplissage du formulaire",
  DOCUMENTS: "Dépôt des documents",
  INTERVIEW: "Faire l'entretien",
  INTERVIEW_SCHEDULED: "Entretien programmé",
  FINISH: "Résultat",
};

const List = () => {
  const { user } = useStore();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchNegociations();
  }, []);

  const fetchNegociations = async () => {
    setLoading(true);
    try {
      const query = {
        valuation: true,
        user_id: user._id,
      };
      const res = await api.post("/company/search", query);
      if (res.ok) {
        const data = res.data;
        setCompanies(data);
      }
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  if (loading)
    return (
      <div className="flex justify-center items-center col-span-4">
        <Loader />
      </div>
    );

  if (companies.length === 0)
    return (
      <div className="flex flex-col justify-center items-center mt-10">
        <h1 className="text-2xl font-semibold text-black mb-8">Bienvenue sur l'outil de valorisation</h1>
        <p className="font-medium text-center w-2/3">
          Vous souhaitez connaître la valeur de votre entreprise ? Nous vous proposons de réaliser une valorisation de votre entreprise. Vous pouvez prendre un rendez-vous dès
          maintenant pour réaliser une valorisation de votre entreprise.
        </p>
        <Link to="/valuation/new" className="primary-button mt-10">
          Démarrer une valorisation
        </Link>
      </div>
    );

  return (
    <div className="p-8">
      <header className="w-full flex items-center justify-between">
        <div className="space-y-2">
          <h1 className="text-3xl font-semibold text-black mb-2">Valorisations de mes entreprises</h1>
          <p className="text-base text-gray-500">Retrouvez ici toutes vos valorisations en cours</p>
        </div>

        <Link to="/valuation/new" className="primary-button">
          Démarrer une valorisation
        </Link>
      </header>

      <div className="flex justify-between items-center my-5 px-6">
        <h3 className="w-14" />
        <h3 className="flex-1 text-sm font-medium">Entreprise</h3>
        <h3 className="w-1/4 text-sm font-medium text-center">Statut</h3>
        <h3 className="flex-1 text-sm font-medium">Étape en cours</h3>
        <h3 className="w-1/6 text-sm font-medium">Date de commencement</h3>
      </div>
      <div className="flex flex-col gap-3">
        {companies.map((e, index) => {
          const valuationType = e.valuation_type ? e.valuation_type.toLowerCase() : "sme";
          let link = `/valuation/${valuationType}/${e._id}/welcome`;
          if (e.valuation_step === "FORM") link = `/valuation/${valuationType}/${e._id}/form-human`;
          if (e.valuation_step === "DOCUMENTS") link = `/valuation/${valuationType}/${e._id}/documents`;
          if (e.valuation_step === "INTERVIEW") link = `/valuation/${valuationType}/${e._id}/interview`;
          if (e.valuation_step === "FINISH" || e.valuation_step === "INTERVIEW_SCHEDULED") link = `/valuation/${valuationType}/${e._id}/result`;
          return (
            <Link to={link} key={index} className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center py-4 px-6">
              <div className="w-14 flex justify-start">{PLAN[e.valuation_plan]}</div>
              <div className="flex-1 items-center flex">
                <h1 className="text-sm font-semibold ml-5">{e.company_name}</h1>
              </div>
              <div className="w-1/4 flex items-center justify-center gap-4">{STATUS[e.status]}</div>
              <div className="flex-1">{STEP_LABEL[e.valuation_step]} </div>
              <div className="w-1/6">{new Date(e.valuation_started_at || e.created_at).toLocaleDateString("fr-FR")}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default List;
