import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiUsers } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { AiOutlineShop } from "react-icons/ai";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { LiaCoinsSolid } from "react-icons/lia";
import { PiPlant } from "react-icons/pi";
import { GoGear } from "react-icons/go";
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill } from "react-icons/bs";

import Ad from "./Ad";
import SellingPrice from "./SellingPrice";
import Funding from "./Funding";
import Financials from "./Financials";
import Documents from "./Documents";
import Company from "./Company";
import toast from "react-hot-toast";
import api from "@/services/api";
import Location from "./Location";

const Create = () => {
  const { id } = useParams();
  const [values, setValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setValues(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div className="h-full flex flex-col p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Ajouter une annonce de {values.annonce_type === "levee" ? "levée" : "cession"}</h1>
        <p className="text-base text-gray-500">Ajoutez votre une annonce de {values.annonce_type === "levee" ? "levée" : "cession"} d'entreprise</p>
      </header>
      <div className="w-full flex mt-10 gap-6">
        <Navigation values={values} />
        {/* <div className="w-px h-full bg-black" /> */}
        <section className="flex flex-col flex-1 items-center border-l border-black px-8">
          <Routes>
            <Route path="/" element={<Navigate to="company" />} />
            <Route path="/company" element={<Company />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/location" element={<Location />} />
            <Route path="/financials" element={<Financials />} />
            <Route path="/funds" element={values.annonce_type === "levee" ? <Funding /> : <SellingPrice />} />
            <Route path="/ad" element={<Ad />} />
          </Routes>
        </section>
      </div>
    </div>
  );
};

const Navigation = ({ values }) => {
  const [step, setStep] = useState("product");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("company")) {
      setStep("company");
    } else if (location.pathname.includes("location")) {
      setStep("location");
    } else if (location.pathname.includes("documents")) {
      setStep("documents");
    } else if (location.pathname.includes("financials")) {
      setStep("financials");
    } else if (location.pathname.includes("funds")) {
      setStep("funds");
    } else if (location.pathname.includes("ad")) {
      setStep("ad");
    }
  }, [location]);

  return (
    <ul className="space-y-6">
      <li>
        <div className={`flex items-center ${step === "company" ? "opacity-100" : "opacity-50"}`}>
          <Bs1CircleFill className="mr-3" />
          <h1 className="font-normal">Informations Générales</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "documents" ? "opacity-100" : "opacity-50"}`}>
          <Bs2CircleFill className="mr-3" />
          <h1 className="font-normal">Documents</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "location" ? "opacity-100" : "opacity-50"}`}>
          <Bs3CircleFill className="mr-3" />
          <h1 className="font-normal">Localisation</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "financials" ? "opacity-100" : "opacity-50"}`}>
          <Bs4CircleFill className="mr-3" />
          <h1 className="font-normal">Informations financières</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "funds" ? "opacity-100" : "opacity-50"}`}>
          <Bs5CircleFill className="mr-3" />
          <h1 className="font-normal">{values.annonce_type === "levee" ? "Estimation" : "Prix de cession"}</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "ad" ? "opacity-100" : "opacity-50"}`}>
          <Bs6CircleFill className="mr-3" />
          <h1 className="font-normal">Votre annonce</h1>
        </div>
      </li>
    </ul>
  );
};

export default Create;
