import React from "react";
import { useTranslation } from "react-i18next";
import { TfiMenuAlt } from "react-icons/tfi";
import { FaCloudUploadAlt, FaAward } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { IoGift } from "react-icons/io5";

import { Link, useParams } from "react-router-dom";
import useStore from "@/services/store";

const Welcome = () => {
  const { id } = useParams();
  const { user } = useStore();
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col justify-center items-center gap-8">
      <div>
        <h1 className="text-center text-3xl text-black font-semibold">
          {" "}
          {t("welcome.title")} {user.first_name}
        </h1>
        <p className="text-gray-500 text-center mt-2"> {t("welcome.info_text")}</p>
        <p className="text-center"> {t("welcome.here_the_process_to_follow")}</p>
      </div>

      <div className="w-1/2 border-gray-200 border rounded-md p-4 flex gap-4">
        <div className="w-1/6 flex items-center">
          <span className="mr-4 text-lg font-bold text-primary">1.</span>
          <TfiMenuAlt className="w-14 h-14 p-4 bg-primary text-white rounded-lg" />
        </div>
        <div className="flex-1">
          <p className=" font-semibold">{t("welcome.form")}</p>
          <p className="text-gray-500 text-sm">{t("welcome.form_description")}</p>
        </div>
      </div>

      <div className="w-1/2 border-gray-200 border rounded-md p-4 flex gap-4">
        <div className="w-1/6 flex items-center">
          <span className="mr-4 text-lg font-bold text-primary">2.</span>
          <FaCloudUploadAlt className="w-14 h-14 p-4 bg-primary text-white rounded-lg" />
        </div>
        <div className="flex-1">
          <p className="font-semibold">{t("welcome.documents")}</p>
          <p className="text-gray-500 text-sm">{t("welcome.documents_description")}</p>
        </div>
      </div>
      <div className="w-1/2 border-gray-200 border rounded-md p-4 flex gap-4">
        <div className="w-1/6 flex items-center">
          <span className="mr-4 text-lg font-bold text-primary">3.</span>
          <FaPhone className="w-14 h-14 p-4 bg-primary text-white rounded-lg" />
        </div>
        <div className="flex-1">
          <p className="font-semibold">{t("welcome.vc_analyst")}</p>
          <p className="text-gray-500 text-sm">{t("welcome.vc_analyst_description")}</p>
        </div>
      </div>
      <div className="w-1/2 border-gray-200 border rounded-md p-4 flex gap-4">
        <div className="w-1/6 flex items-center">
          <span className="mr-4 text-lg font-bold text-primary">4.</span>
          <IoGift className="w-14 h-14 p-4 bg-primary text-white rounded-lg" />
        </div>
        <div className="flex-1">
          <p className="font-semibold">{t("welcome.report")}</p>
          <p className="text-gray-500 text-sm">{t("welcome.report_description")}</p>
        </div>
      </div>
      <div className="text-gray-500 text-sm">
        <p>{t("welcome.asterix")}</p>
      </div>

      {/* <div className="w-1/2 flex flex-row gap-8 justify-between">
        <div className="w-1/2 border-gray-200 border rounded-md p-4 flex gap-4">
          <div className="flex items-center">
            <FaAward className="w-14 h-14 p-4 bg-primary text-white rounded-lg" />
          </div>
          <div className="flex-1">
            <p className="font-semibold">2059</p>
            <p className="text-gray-500 text-sm">{t("welcome.startup_analyst")}</p>
          </div>
        </div>
        <div className="w-1/2 border-gray-200 border rounded-md p-4 flex gap-4">
          <div className="flex items-center">
            <FaAward className="w-14 h-14 p-4 bg-primary text-white rounded-lg" />
          </div>
          <div className="flex-1">
            <p className="font-semibold">140</p>
            <p className="text-gray-500 text-sm">{t("welcome.startup_analyst_with_the_same_maturity")}</p>
          </div>
        </div>
      </div> */}

      <div className="flex justify-between w-1/2 my-10">
        <Link to={`../../../valuation`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <Link to={`/valuation/start-up/${id}/product`} className="primary-button px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
