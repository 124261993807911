import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import api from "@/services/api";
import Loader from "@/components/Loader";
import SingleSelect from "../components/SingleSelect";

const OfferCompetition = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [questions, setQuestions] = useState();
  const [values, setValues] = useState();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const res = await api.post("/question/search", { limit: 300, startup: true });
        if (res.ok) {
          setQuestions(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchQuestions();

    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          if (!res.data.plan) res.data.plan = "certified";
          setValues(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <SingleSelect question={questions?.find((q) => q.key === "business_costs_position")} values={values} setValues={setValues} />
      <SingleSelect question={questions?.find((q) => q.key === "cost_position")} values={values} setValues={setValues} />
      <SingleSelect question={questions?.find((q) => q.key === "differentiation_position")} values={values} setValues={setValues} />
      <SingleSelect question={questions?.find((q) => q.key === "quality_position")} values={values} setValues={setValues} />
      <SingleSelect question={questions?.find((q) => q.key === "close_competitor_quality")} values={values} setValues={setValues} />

      <div className="flex justify-between w-full">
        <Link to={`../form-offer-product`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <Link to={`../form-offer-launch`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default OfferCompetition;
