import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";

const Situation = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState({
  });

  useEffect(() => {
    fetchData();
  }, [companyId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${companyId}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, values);
      if (res.ok) {
        setValues(res.data);
        //   toast.success("Company updated successfully");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

 

  if (!values) return <Loader />;

  const total =
    (values?.funds_usage_recruitment || 0) +
    (values?.funds_usage_operational_costs || 0) +
    (values?.funds_usage_rnd || 0) +
    (values?.funds_usage_communication || 0) +
    (values?.funds_usage_managers_salary || 0) +
    (values?.funds_usage_other || 0);

  return (
    <div className="w-full space-y-8 px-40">
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.is_ur_business_already_profitable")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input type="radio" id="profitable_yes" name="is_profitable" checked={values.is_profitable} onChange={() => setValues((prev) => ({ ...prev, is_profitable: true }))} />
            <label className="text-sm" htmlFor="profitable_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input type="radio" id="profitable_no" name="is_profitable" checked={!values.is_profitable} onChange={() => setValues((prev) => ({ ...prev, is_profitable: false }))} />
            <label className="text-sm" htmlFor="profitable_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.current_cash_balance")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.cash_flow ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.cash_flow || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, cash_flow: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.current_monthly_cash_burn")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.current_monthly_cash_burn? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.current_monthly_cash_burn || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, current_monthly_cash_burn: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.have_you_recevied_non_repavable_grants")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="repavable_grants_yes"
              name="has_received_non_repavable_grants"
              checked={values.has_received_non_repavable_grants}
              onChange={() => setValues((prev) => ({ ...prev, has_received_non_repavable_grants: true }))}
            />
            <label className="text-sm" htmlFor="repavable_grants_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="repavable_grants_no"
              name="has_received_non_repavable_grants"
              checked={!values.has_received_non_repavable_grants}
              onChange={() => setValues((prev) => ({ ...prev, has_received_non_repavable_grants: false }))}
            />
            <label className="text-sm" htmlFor="repavable_grants_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.total_amount_of_subsidies_you_have_received")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.total_amount_subsidies_received ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className=" w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.total_amount_subsidies_received || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, total_amount_subsidies_received: Number(e.target.value) }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.are_u_looking_for_funds")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="funds_yes"
              name="is_looking_for_funds"
              checked={values.is_looking_for_funds}
              onChange={() => setValues((prev) => ({ ...prev, is_looking_for_funds: true }))}
            />
            <label className="text-sm" htmlFor="funds_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="funds_no"
              name="is_looking_for_funds"
              checked={!values.is_looking_for_funds}
              onChange={() => setValues((prev) => ({ ...prev, is_looking_for_funds: false }))}
            />
            <label className="text-sm" htmlFor="funds_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.amounts_of_funds_need_to_cover_for_next_18_months")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.funds_needed  ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.funds_needed || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_needed: Number(e.target.value) }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.targeted_dilution_level")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.dilution_target ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.dilution_target || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, dilution_target: Number(e.target.value) }))}
          />
          <span className="px-6 border-l"> % </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.intended_use_of_funds")}
          <span className="ml-2 text-red-500">*</span>
        </label>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.recruit")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_recruitment || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_recruitment: Number(e.target.value) }))}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.operational_costs")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_operational_costs || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_operational_costs: Number(e.target.value) }))}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.conducting")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_rnd || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_rnd: Number(e.target.value) }))}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.operations")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_communication || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_communication: Number(e.target.value) }))}
          />
          <span>%</span>
        </div>

        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.managers")}</p>

          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_managers_salary || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_managers_salary: Number(e.target.value) }))}
          />
          <span>%</span>
        </div>
        <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
          <p className="flex-1 text-sm">{t("lable.other_uses")}</p>
          <input
            type="number"
            className="border-l px-3 appearance-none focus:outline-none py-3 w-16"
            value={values?.funds_usage_other || 0}
            onChange={(e) => setValues((prev) => ({ ...prev, funds_usage_other: Number(e.target.value) }))}
          />
          <span>%</span>
        </div>

        <div className="flex justify-between items-center mt-3">
          <p>{t("lable.total")}</p> <div>{total || 0} %</div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.when_finalize_the_fundraising")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className={`${values?.fundraising_finalized_at? 'border-2 border-primary text-primary filled-input' : 'input'}`}
          placeholder={t("placeholder_enter")}
          value={values?.fundraising_finalized_at || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, fundraising_finalized_at: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.percentage_of_share_you_are_willing_to_diverse_for_fundraising")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.share_percentage_divest ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.share_percentage_divest || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, share_percentage_divest: e.target.value }))}
          />
          <span className="px-6 border-l"> % </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.have_you_raised_funds_from_pro_in_past")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="raised_funds_yes"
              name="has_raised_funds_in_past"
              checked={values.has_raised_funds_in_past}
              onChange={() => setValues((prev) => ({ ...prev, has_raised_funds_in_past: true }))}
            />
            <label className="text-sm" htmlFor="raised_funds_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="raised_funds_no"
              name="has_raised_funds_in_past"
              checked={!values.has_raised_funds_in_past}
              onChange={() => setValues((prev) => ({ ...prev, has_raised_funds_in_past: false }))}
            />
            <label className="text-sm" htmlFor="raised_funds_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.have_you_ever_been_valued")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="valued_yes"
              name="has_been_valued"
              checked={values.has_been_valued}
              onChange={() => setValues((prev) => ({ ...prev, has_been_valued: true }))}
            />
            <label className="text-sm" htmlFor="valued_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="valued_no"
              name="has_been_valued"
              checked={!values.has_been_valued}
              onChange={() => setValues((prev) => ({ ...prev, has_been_valued: false }))}
            />
            <label className="text-sm" htmlFor="valued_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.last_valuation")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.last_valuation  ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.last_valuation || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, last_valuation: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
     <label className="text-black font-semibold text-sm">
    {t("lable.when_was_last_valuation")} <span className="ml-2 text-red-500">*</span>
     </label>
      <input
        className={`${values?.last_valuation_date ? 'border-2 border-primary text-primary filled-input' : 'input'}`}
        placeholder={t("placeholder_date")}
        value={values?.last_valuation_date || ""}
        onChange={(e) => setValues((prev) => ({ ...prev, last_valuation_date: e.target.value }))}
      />
     </div>


      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.context_and_who_conducted_it")} <span className="ml-2 text-red-500">*</span>
        </label>
        <textarea
          className={`${values?.last_valuation_context ? 'border-2 border-primary text-primary filled-input' : 'input min-h-32'}`}
          placeholder={t("placeholder_enter")}
          value={values?.last_valuation_context || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, last_valuation_context: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.net_income_last_year")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.net_income_5 ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.net_income_5 || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, net_income_5: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <p className=" text-lg font-semibold"> {t("lable.recent_company_balance_sheet")}</p>
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.intangible_assets")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.intangible_assets? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.intangible_assets || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, intangible_assets: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.tangible_fixed")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.tangible_fixed  ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.tangible_fixed || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, tangible_fixed: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.stocks")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.stocks ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.stocks || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, stocks: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.customer_receivables")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.customer_receivables? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.customer_receivables || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, customer_receivables: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.equity_capital")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.equity_capital ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.equity_capital || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, equity_capital: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.amout_of_shareholders")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.amout_of_shareholders  ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.amout_of_shareholders || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, amout_of_shareholders: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.operating_liabilities")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.operating_liabilities ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.operating_liabilities || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, operating_liabilities: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.financial_debt")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className={`${values?.debt ? 'border-2 border-primary text-primary filled-input' : 'flex gap-2 input justify-between items-center '}`}>
          <input
            type="number"
            className="w-[90%] focus:outline-none"
            placeholder={t("placeholder_enter")}
            value={values?.debt || ""}
            onChange={(e) => setValues((prev) => ({ ...prev, debt: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <Link to={`../form-innovation`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <Link to={`../form-finance-business-plan`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default Situation;
