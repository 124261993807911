import { useEffect, useState } from "react";

import api from "@/services/api";
import { Link, useNavigate } from "react-router-dom";
import useStore from "@/services/store";
import Loader from "@/components/Loader";
import toast from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi";
import ConfirmModal from "@/components/ConfirmModal";

const ANNONCE_TYPE = {
  cession: <p className="text-primary text-xs border border-primary p-1 rounded text-center">Cession</p>,
  reprise: <p className="text-green-700 text-xs border border-yellow-600  p-1 rounded text-center">Reprise</p>,
  levee: <p className="text-yellow-600 text-xs border border-yellow-600  p-1 rounded text-center">Levée</p>,
};

const INVESTOR_TYPE = {
  buyer: <p className="text-primary text-xs border border-primary p-1 rounded text-center">Acquéreur</p>,
  investor: <p className="text-yellow-600 text-xs border border-yellow-600  p-1 rounded text-center">Investisseur</p>,
};

const ANNONCE_STATUS = {
  DRAFT: <p className="text-gray-500 text-center">En cours de création</p>,
  PENDING: <p className="text-yellow-500 text-center">En attente</p>,
  ACCEPTED: <p className="text-green-500 text-center">Validé</p>,
  REJECTED: <p className="text-red-500 text-center">Refusé</p>,
};

const Home = () => {
  const [companyAds, setCompanyAds] = useState([]);
  const [investorAds, setInvestorAds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { user } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await api.post("/company/search", { user_id: user._id, annonce: true });
        if (res.ok) setCompanyAds(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération de vos annonces de levée/cession");
      }

      try {
        setLoading(true);
        const res = await api.post("/investor/search", { user_id: user._id, annonce: true });
        if (res.ok) setInvestorAds(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération de vos annonces d'acquisition/investissement");
      }
    };
    fetchData();
  }, []);

  const buildLink = (annonce) => {
    let path = "";
    if (annonce.annonce_type === "levee" || annonce.annonce_type === "cession") {
      if (annonce.annonce_status === "DRAFT") path = `/annonce/${annonce._id}/create`;
      else if (annonce.annonce_status === "PENDING" || annonce.annonce_status === "REJECTED") path = `/annonce/${annonce._id}`;
      else path = `/annonce/${annonce._id}`;
    } else {
      if (annonce.annonce_status === "DRAFT") path = `/investisseurs/${annonce._id}/create`;
      else if (annonce.annonce_status === "PENDING" || annonce.annonce_status === "REJECTED") path = `/investisseurs/${annonce._id}`;
      else path = `/investisseurs/${annonce._id}`;
    }
    return path;
  };

  const handleClickDelete = (item, e) => {
    console.log("item", item);
    console.log("e", e);
    e.preventDefault();
    setSelectedItem(item);
    setShowDeleteModal(true);
    console.log("selectedItem", selectedItem);
  };

  const handleDelete = async () => {
    try {
      console.log("selectedItem delete", selectedItem);

      let res;
      if (selectedItem.annonce_type === "cession" || selectedItem.annonce_type === "levee") res = await api.delete(`/company/${selectedItem._id}`);
      else res = await api.delete(`/investor/${selectedItem._id}`);

      if (res.ok) {
        toast.success("Votre annonce a bien été supprimée");
        navigate(0);
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="p-8 space-y-8">
      <ConfirmModal
        show={showDeleteModal}
        title="Supprimer votre annonce"
        description="Êtes-vous sûr de vouloir supprimer votre annonce ?"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        confirmClassname="red-button"
      />
      <header className="w-full flex items-center justify-between">
        <div className="space-y-2">
          <h1 className="text-3xl font-semibold text-black mb-2">Mes annonces</h1>
          <p className="text-base text-gray-500">Retrouvez ici toutes vos annonces</p>
        </div>
        {companyAds.length > 0 ? (
          <Link to="/annonce/new" className="primary-button">
            Déposer une nouvelle annonce
          </Link>
        ) : null}
      </header>

      {companyAds.length > 0 ? (
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-3">
            <h2 className="text-xl text-primary font-semibold">Annonces de cession/levée de fonds</h2>
            <div className="flex justify-between items-center mt-3 px-8">
              <h3 className="w-10" />
              <h3 className="w-1/6 flex text-sm font-medium">Type</h3>
              <h3 className="flex-1 text-sm font-medium ml-5">Entreprise</h3>
              <h3 className="w-1/6 text-sm font-medium">Date de création</h3>
              <h3 className="w-1/4 text-sm font-medium text-center">Statut</h3>
              <div className="p-4"></div>
            </div>
            <div className="flex flex-col gap-3">
              {companyAds.map((item, index) => (
                <Link to={buildLink(item)} key={index} className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center py-4 px-6">
                  <h3 className="w-10" />

                  <div className="w-1/6 flex justify-start">{ANNONCE_TYPE[item.annonce_type]}</div>
                  <div className="flex-1 items-center flex">
                    <h1 className="text-sm font-semibold ml-5">{item.company_name}</h1>
                  </div>
                  <div className="w-1/6">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
                  <div className="w-1/4 flex items-center justify-center gap-4">{ANNONCE_STATUS[item.annonce_status]}</div>
                  <button onClick={(e) => handleClickDelete(item, e)} className="hover:bg-red-400 rounded h-full p-2">
                    <HiOutlineTrash />
                  </button>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-40">
          <p className="mb-6">Vous n'avez pas encore d'annonce de cession/levée de fonds.</p>
          <Link to="/annonce/new" className="primary-button">
            Créer une annonce
          </Link>
        </div>
      )}
      {investorAds.length > 0 ? (
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-3">
            <h2 className="text-xl text-primary font-semibold">Annonces d'acquisition/investissement</h2>
            <div className="flex justify-between items-center mt-3 px-8">
              <h3 className="w-10" />
              <h3 className="w-1/6 text-sm font-medium">Type</h3>
              <h3 className="flex-1 text-sm font-medium ml-5">Nom de l'annonce</h3>
              <h3 className="w-1/6 text-sm font-medium">Date de création</h3>
              <h3 className="w-1/4 text-sm font-medium text-center">Statut</h3>
              <div className="p-4"></div>
            </div>
            <div className="flex flex-col gap-3">
              {investorAds.map((item, index) => (
                <Link to={buildLink(item)} key={index} className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center py-4 px-6">
                  <h3 className="w-10" />
                  <div className="w-1/6 flex justify-start">{INVESTOR_TYPE[item.annonce_type]}</div>
                  <div className="flex-1 items-center flex">
                    <h1 className="text-sm font-semibold ml-5">{item.annonce_type === "buyer" ? item.buyer_title : item.structure_name}</h1>
                  </div>
                  <div className="w-1/6">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
                  <div className="w-1/4 flex items-center justify-center gap-4">{ANNONCE_STATUS[item.annonce_status]}</div>
                  <button onClick={(e) => handleClickDelete(item, e)} className="hover:bg-red-400 rounded h-full p-2">
                    <HiOutlineTrash />
                  </button>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-40">
          <p className="mb-6">Vous n'avez pas encore d'annonce d'acquisition/investissement.</p>
          <Link to="/annonce/new" className="primary-button">
            Créer une annonce
          </Link>
        </div>
      )}
    </div>
  );
};

export default Home;
