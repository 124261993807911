import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { TbStack } from "react-icons/tb";
import { API_URL } from "../../../services/config";

import { CALENDLY_COMMERCIAL } from "../../../services/config";
import api from "@/services/api";
import Loader from "@/components/Loader";

const Product = () => {
  const { id } = useParams();
  const [company, setCompany] = useState();
  const { t } = useTranslation();

  const NOTATION = [t("product.notation.first"), t("product.notation.second")];

  const ACCELERATED = [
    t("product.accelerated.first"),
    t("product.accelerated.second"),
    t("product.accelerated.third"),
    t("product.accelerated.four"),
    t("product.accelerated.five.startup"),
    t("product.accelerated.six"),
    t("product.accelerated.seven"),
    t("product.accelerated.eight"),
    t("product.accelerated.nine"),
    t("product.accelerated.ten"),
  ];

  const CERTIFIED = [
    t("product.certified.first"),
    t("product.certified.second"),
    t("product.certified.third"),
    t("product.certified.four"),
    t("product.certified.five"),
    t("product.certified.six"),
    t("product.certified.seven"),
    t("product.certified.eight.startup"),
    t("product.certified.nine"),
    t("product.certified.ten"),
    t("product.certified.eleven"),
    t("product.certified.twelve"),
    t("product.certified.thirsteen"),
    t("product.certified.fourteen"),
  ];

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.valuation_plan) res.data.valuation_plan = "CERTIFIED";
        setCompany(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!company) return <Loader />;

  return (
    <div className="w-full flex flex-col justify-center items-center gap-8">
      <div>
        <h1 className="text-center text-3xl text-black font-semibold">{t("product.title")}</h1>
      </div>
      <div className="grid grid-cols-3 gap-8">
        <button
          className={`border-2 ${company.valuation_plan === "NOTATION" ? "border-primary" : "border-secondary"} rounded-lg p-6 text-primary flex flex-col items-center h-full`}
          onClick={() => setCompany({ ...company, valuation_plan: "NOTATION" })}
        >
          <HiOutlineLightningBolt className="text-4xl text-primary" />
          <p className="text-sm font-semibold mt-2 mb-4">{t("product.accelerated.time")}</p>
          <div className="flex gap-2 items-end text-black">
            <h2 className="text-3xl font-semibold">{t("product.notation.price")}</h2>
            <p className="font-semibold">{t("product.notation.taxes")}</p>
          </div>
          <p className="mt-3 font-semibold uppercase">{t("product.notation.title")}</p>
          <p className="mt-3 font-semibold">Pré & Post-revenu</p>

          <div className="mt-6 flex-1">
            <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
            <ul className="flex flex-col gap-3 mt-4 justify-center">
              {NOTATION?.map((a, i) => (
                <li className="flex gap-2 text-sm" key={i}>
                  <div className="bg-light_blue rounded-full p-1">
                    <FaCheck className="text-xs" />
                  </div>

                  <p>{a}</p>
                </li>
              ))}
            </ul>
          </div>

          <form action={`${API_URL}/payment/notation-start-up`} method="POST">
            <input type="hidden" name="companyId" value={id} />
            <button className="primary-button" type="submit">
              {t("product.select")}
            </button>
          </form>
        </button>
        <button
          className={`border-2 ${company.valuation_plan === "ACCELERATED" ? "border-primary" : "border-secondary"} rounded-lg p-6 text-primary flex flex-col items-center h-full`}
          onClick={() => setCompany({ ...company, valuation_plan: "ACCELERATED" })}
        >
          <HiOutlineLightningBolt className="text-4xl text-primary" />
          <p className="text-sm font-semibold mt-2 mb-4">{t("product.accelerated.time")}</p>
          <div className="flex gap-2 items-end text-black">
            <h2 className="text-3xl font-semibold">{t("product.accelerated.price")}</h2>
            <p className="font-semibold">{t("product.accelerated.taxes")}</p>
          </div>
          <p className="mt-3 font-semibold uppercase">{t("product.accelerated.title")}</p>
          <p className="mt-3 font-semibold">{t("product.accelerated.maturity")}</p>

          <div className="mt-6 flex-1">
            <p className="text-sm font-semibold">{t("product.accelerated.include")}</p>
            <ul className="flex flex-col gap-3 mt-4 justify-center">
              {ACCELERATED?.map((a, i) => (
                <li className="flex gap-2 text-sm" key={i}>
                  <div className="bg-light_blue rounded-full p-1">
                    <FaCheck className="text-xs" />
                  </div>

                  <p>{a}</p>
                </li>
              ))}
            </ul>
          </div>

          <form action={`${API_URL}/payment/accelerated-start-up`} method="POST">
            <input type="hidden" name="companyId" value={id} />
            <button className="primary-button" type="submit">
              {t("product.select")}
            </button>
          </form>
        </button>
        <button
          className={`border-2 ${company.valuation_plan === "CERTIFIED" ? "border-primary" : "border-secondary"} rounded-lg p-6 text-primary flex flex-col items-center h-full`}
          onClick={() => setCompany({ ...company, valuation_plan: "CERTIFIED" })}
        >
          <TbStack className="text-4xl text-primary rounded-lg" />
          <p className="text-sm font-semibold mt-2 mb-4">{t("product.certified.popular")}</p>
          <div className="flex gap-2 items-end text-black">
            <h1 className="text-3xl font-semibold">{t("product.certified.price")}</h1>
            <p className="font-semibold">{t("product.certified.taxes")}</p>
          </div>
          <p className="mt-3 font-semibold uppercase">{t("product.certified.title")}</p>
          <p className="mt-3 font-semibold">{t("product.certified.maturity")}</p>

          <div className="mt-6">
            <p className="text-sm font-semibold">{t("product.certified.include")}</p>
            <ul className="flex flex-col gap-3 mt-4 justify-center">
              {CERTIFIED?.map((a, i) => (
                <li className="flex gap-2 text-sm" key={i}>
                  <div className="bg-light_blue rounded-full p-1">
                    <FaCheck className="text-xs" />
                  </div>

                  <p>{a}</p>
                </li>
              ))}
            </ul>
          </div>
          <form action={`${API_URL}/payment/certified-start-up`} method="POST" className="mt-8">
            <input type="hidden" name="companyId" value={id} />
            <button className="primary-button">{t("product.select")}</button>
          </form>
        </button>
      </div>

      <div className="mt-5">
        <h2 className="text-lg text-primary font-semibold">{t("product.bizzdev")}</h2>

        <div className="flex items-center justify-center gap-4 mt-2">
          <a href={CALENDLY_COMMERCIAL} target="_blank" className="empty-button">
            {t("product.contact")}
          </a>
        </div>
      </div>
      <div className="flex justify-between w-1/2 my-10">
        <Link to={`../welcome`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
      </div>
    </div>
  );
};

export default Product;
