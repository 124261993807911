import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import api from "@/services/api.js";

import { useNavigate, useParams } from "react-router-dom";
import useStore from "@/services/store";
import { HiOutlineDownload } from "react-icons/hi";

import { CashFlowAndCAFCard, DcfCard, EbitCard, EbitdaCard, FiscaleCard, MbaAverageCard, NetIncomeAverageCard, ProductivityCard } from "../components/Cards";
import Loader from "../../../components/Loader";

const Result = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { user } = useStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleReschedule = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, { valuation_status: "PENDING", valuation_step: "INTERVIEW" });
      if (res.ok) {
        navigate(`../interview`);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Une erreur est survenue");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/company/${companyId}`);
      if (res.ok) {
        const data = res.data;
        setCompanies(data);
      }
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  if (companies && companies.valuation_status === "FINISHED")
    return (
      <div className="w-full h-full space-y-8 px-40">
        <h1 className="font-semibold text-xl">
          {t("result.welcome")} {user.first_name}
        </h1>
        <p className="text-base text-gray-500">{t("result.finish.text")}</p>

        <div className="flex justify-end">
          <button type="button" className="primary-button flex gap-2">
            {t("result.download")}
            <HiOutlineDownload className="w-5" />
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <DcfCard />
          <EbitdaCard />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <EbitCard />
          <FiscaleCard />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <ProductivityCard />
          <NetIncomeAverageCard />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <CashFlowAndCAFCard />
          <MbaAverageCard />
        </div>
      </div>
    );

  return (
    <div className="w-full h-full space-y-8 px-40">
      <h1 className="font-semibold text-center text-xl">{t("result_not_done.title")}</h1>
      <p className="text-base text-center  text-gray-500">{t("result_not_done.text")}</p>
    </div>
  );
};

export default Result;
