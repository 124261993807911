import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";
import i18n from "./lang";

import ResetPassword from "@/pages/auth/ResetPassword";
import ForgotPassword from "@/pages/auth/ForgotPassword";
import Signin from "@/pages/auth/Signin";
import Home from "@/pages/home";
import Account from "@/pages/account";
import Annonce from "@/pages/annonce";
import Discussion from "@/pages/discussion";
import OnBoard from "@/pages/onboard";
import MyAnnonce from "@/pages/my-annonce";
import Valuation from "@/pages/valuation";
import Investors from "@/pages/investors";
import Buyers from "@/pages/buyers";
import TopBar from "./components/TopBar";
import Navbar from "@/components/NavBar";
import Loader from "@/components/Loader";

import AppLogo from "@/assets/img/logo.png";

import api from "@/services/api";
import useStore from "@/services/store";
import DataRoom from "./pages/dataroom";
import LoginAs from "./pages/auth/LoginAs";

const App = () => {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Signin />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              {/* <Route path="/signup" element={<Signup />} /> */}
              <Route path="/connect" element={<LoginAs />} />
            </Route>
            <Route element={<OnBoardLayout />}>
              <Route path="/signup/*" element={<OnBoard />} />
            </Route>

            <Route element={<ProtectedLayout />}>
              <Route path="/home/*" element={<Home />} />
              <Route path="/annonce/*" element={<Annonce />} />
              <Route path="/discussion/*" element={<Discussion />} />
              <Route path="/valuation/*" element={<Valuation />} />
              <Route path="/my-annonce/*" element={<MyAnnonce />} />
              <Route path="/data-room/*" element={<DataRoom />} />
              <Route path="/investisseurs/*" element={<Investors />} />
              <Route path="/buyers/*" element={<Buyers />} />
              <Route path="/account" element={<Account />} />
            </Route>
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </BrowserRouter>
        <Toaster position="top-center" />
      </I18nextProvider>
    </>
  );
};

const AuthLayout = () => {
  const { user } = useStore();

  if (user) return <Navigate to="/" replace={true} />;
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <div className="w-24 mb-4">
        <img className="w-full" src={AppLogo} alt="logo" />
      </div>
      <div className="w-1/2 max-w-xl">
        <Outlet />
      </div>
    </div>
  );
};

const OnBoardLayout = () => {
  return (
    <div className="flex flex-col items-center w-screen h-screen overflow-x-hidden">
      <div className="w-24 mb-4 mt-20">
        <img className="w-full" src={AppLogo} alt="logo" />
      </div>
      <div className="w-1/2 max-w-xl">
        <Outlet />
      </div>
    </div>
  );
};

const ProtectedLayout = () => {
  const { user, setUser } = useStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api
      .get("/user/refresh")
      .then((res) => {
        if (res.ok) {
          setUser(res.data.user);
          api.setToken(res.data.token);
        } else {
          setUser(null);
          navigate("/login");
        }
        setLoading(false);
      })
      .catch(() => setUser(null));
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!user) return <Navigate to="/login" replace={true} />;

  return (
    <>
      <div className="relative w-screen h-screen">
        <nav className="w-36 absolute left-0 top-0">
          <Navbar />
        </nav>
        <main className="ml-36 h-full overflow-auto">
          <div className="h-14 border-b border-secondary">
            <TopBar />
          </div>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default App;
