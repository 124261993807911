import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { IoCheckbox, IoCloseSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin4Line } from "react-icons/ri";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal";
import CompetitorModal from "./components/CompetitorModal";
import SingleSelect from "../components/SingleSelect";

const MarketCompetitors = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [elementIndex, setElementIndex] = useState({});

  useEffect(() => {
    fetchQuestions();
    fetchData();
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const fetchQuestions = async () => {
    try {
      const res = await api.post("/question/search", { limit: 300, startup: true });
      if (res.ok) {
        setQuestions(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      // if (res.ok) {
      //   toast.success("Company updated successfully");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = async (v) => {
    setValues((prev) => ({
      ...prev,
      competitors: prev.competitors.map((competitor, i) => (i === elementIndex ? v : competitor)),
    }));
    setIsOpen(true);
  };

  const handleDelete = async (l, index) => {
    setValues((prev) => ({
      ...prev,
      competitors: prev.competitors.filter((_, i) => i !== index),
    }));
  };

  const handleAdd = async () => {
    const newCompetitor = { leader: "" };
    setValues((prev) => ({
      ...prev,
      competitors: [...prev.competitors, newCompetitor],
    }));
    setElementIndex(values.competitors.length);
    setIsOpen(true);
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <SingleSelect question={questions?.find((q) => q.key === "competitors_size")} values={values} setValues={setValues} />

      <div className="mb-6">
        <p className="text-lg mb-5 font-semibold">{t("lable.your_competitors")}</p>

        <div className="flex flex-col gap-2">
          {values?.competitors.length !== 0 &&
            values?.competitors?.map((com, index) => (
              <div
                key={index}
                className="appearance-none py-1  focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center"
              >
                <p>{com?.competitor_name}</p>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setElementIndex(index);
                      setIsOpen(true);
                    }}
                  >
                    <MdEdit className="w-10 p-2 h-10 border rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-50" />
                  </button>
                  <button onClick={() => handleDelete(com, index)}>
                    <RiDeleteBin4Line className="w-10 p-2 h-10 border rounded-md flex justify-center items-center  cursor-pointer hover:bg-gray-50" />
                  </button>
                </div>
              </div>
            ))}

          <button className="w-full bg-gray-200 hover:bg-gray-300 py-2 flex mt-5 justify-center items-center gap-3 rounded" onClick={() => handleAdd()}>
            Ajouter
            <FaPlus />
          </button>

          <Modal
            show={isOpen}
            onClose={() => {
              setIsOpen(false);
              setElementIndex(null);
            }}
          >
            <CompetitorModal setOpen={setIsOpen} competitor={values?.competitors[elementIndex]} setCompetitor={(v) => handleEdit(v)} questions={questions} />
          </Modal>
        </div>
      </div>

      <SingleSelect question={questions?.find((q) => q.key === "target_customers_costs_multiple_use")} values={values} setValues={setValues} />

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.is_it_easy_for_customer_to_switch_from_one_to_other_competing_solution")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="competing_solution_yes"
              name="is_easy_switch"
              onChange={() => setValues((prev) => ({ ...prev, is_easy_switch: true }))}
              checked={values?.is_easy_switch === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="competing_solution_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="competing_solution_no"
              name="is_easy_switch"
              onChange={() => setValues((prev) => ({ ...prev, is_easy_switch: false }))}
              checked={values?.is_easy_switch === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="competing_solution_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <SingleSelect question={questions?.find((q) => q.key === "competitors_pricing_level")} values={values} setValues={setValues} />

      <SingleSelect question={questions?.find((q) => q.key === "strong_market_pricing")} values={values} setValues={setValues} />
      <SingleSelect question={questions?.find((q) => q.key === "is_competitive_env_favorable")} values={values} setValues={setValues} />

      <div className="flex justify-between w-full pb-10">
        <Link to={`../form-market-characteristics`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <Link to={`../form-strategy-partnership`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default MarketCompetitors;
