import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";

const FinanceSituation = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      // if (res.ok) {
      //   toast.success("Company updated successfully");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.is_ur_business_already_profitable")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="profitable_yes"
              name="is_profitable"
              onChange={() => setValues((prev) => ({ ...prev, is_profitable: true }))}
              checked={values?.is_profitable === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="profitable_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="profitable_no"
              name="is_profitable"
              onChange={() => setValues((prev) => ({ ...prev, is_profitable: false }))}
              checked={values?.is_profitable === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="profitable_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.current_cash_balance")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <input
            className=" w-[90%] focus:outline-none "
            placeholder={t("placeholder_enter")}
            value={values?.cash_flow}
            onChange={(e) => setValues((prev) => ({ ...prev, cash_flow: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.current_monthly_cash_burn")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <input
            className=" w-[90%] focus:outline-none "
            placeholder={t("placeholder_enter")}
            value={values?.current_monthly_cash_burn}
            onChange={(e) => setValues((prev) => ({ ...prev, current_monthly_cash_burn: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.net_income_last_year")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <input
            className=" w-[90%] focus:outline-none "
            placeholder={t("placeholder_enter")}
            value={values?.net_income_3}
            onChange={(e) => setValues((prev) => ({ ...prev, net_income_3: e.target.value }))} // why 5?
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <div className="flex justify-between w-full">
        <Link to={`../form-innovation-rnd`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <Link to={`../form-finance-shareholder`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default FinanceSituation;
