import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MdReviews } from "react-icons/md";

import UserFromView from "./components/UserFromView";
import UserToView from "./components/UserToView";

import useStore from "@/services/store";
import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";

const View = () => {
  const { id } = useParams();
  const { user } = useStore();
  const [discussion, setDiscussion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/discussion/${id}`);
        if (res.ok) setDiscussion(res.data);
      } catch (e) {
        toast.error("Une erreur est survenue");
      }
    };

    fetchData();
  }, [id, user._id, user.email, discussion?.interested_id, discussion?.owner_id]);

  if (!discussion) return <Loader />;

  if (discussion.status === "REFUSED") {
    return (
      <div className=" mt-10 flex flex-col justify-center items-center  h-96 ">
        <div className="border rounded-md  flex flex-col justify-center items-center  mx-6 py-10 px-20 text-primary font-semibold gap-4">
          <MdReviews className="text-5xl" /> Votre demande a été refusée.
        </div>
      </div>
    );
  }

  if (discussion.status === "ONGOING") return user._id === discussion.user_from_id ? <UserFromView negociation={discussion} /> : <UserToView negociation={discussion} />;

  return (
    <div className=" mt-10 flex flex-col justify-center items-center  h-96 ">
      <div className="border rounded-md  flex flex-col justify-center items-center  mx-6 py-10 px-20 text-primary font-semibold gap-4">
        <MdReviews className="text-5xl" /> Nous sommes en train de revoir votre demande, vous recevrez une notification dès que nous aurons pris une décision.
      </div>
    </div>
  );
};

export default View;
