import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import api from "@/services/api";
import Loader from "@/components/Loader";

const Human = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [values, setValues] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${companyId}`);
        if (res.ok) {
          if (!res.data.plan) res.data.plan = "certified";
          setValues(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [companyId]);

  useEffect(() => {
    if (!values) return;
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${companyId}`, values);
      // if (res.ok) {
      //   toast.success("Company updated successfully");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!values) return <Loader />;

  const total =
    (values?.team_distribution_marketing || 0) +
    (values?.team_distribution_sales || 0) +
    (values?.team_distribution_engineering || 0) +
    (values?.team_distribution_dev || 0) +
    (values?.team_distribution_product || 0) +
    (values?.team_distribution_finance || 0);

  return (
    <div className="w-full space-y-8 px-40">
      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_many_people")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className={`${values?.founder_count ? "border-2 border-primary text-primary filled-input" : "input"}`}
          placeholder={t("placeholder_enter")}
          value={values?.founder_count || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, founder_count: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_many_full_time_employee")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className={`${values?.employee_count ? "border-2 border-primary text-primary filled-input" : "input"}`}
          placeholder={t("placeholder_enter")}
          value={values?.employee_count || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, employee_count: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_many_trainee")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className={`${values?.trainee_count ? "border-2 border-primary text-primary filled-input" : "input"}`}
          placeholder={t("placeholder_enter")}
          value={values?.trainee_count || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, trainee_count: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.how_many_freelancers")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <input
          className={`${values?.freelancers_count ? "border-2 border-primary text-primary filled-input" : "input"}`}
          placeholder={t("placeholder_enter")}
          value={values?.freelancers_count || ""}
          onChange={(e) => setValues((prev) => ({ ...prev, freelancers_count: e.target.value }))}
        />
      </div>

      <div className="space-y-2">
        <label className="text-black font-semibold text-sm">
          {t("lable.internal_team_perc")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="space-y-2">
          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.marketing")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_marketing || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_marketing: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.sales")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_sales || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_sales: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.engineering")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_engineering || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_engineering: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.code")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_dev || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_dev: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.product")}</p>

            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_product || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_product: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>
          <div className="appearance-none focus:outline-2 focus:outline-gray-200 border rounded-md w-full px-3 text-gray-700 leading-tight flex justify-between items-center">
            <p className="flex-1 text-sm">{t("lable.finance")}</p>
            <input
              type="number"
              className="border-l px-3 appearance-none focus:outline-none py-3 w-20"
              value={values?.team_distribution_finance || 0}
              onChange={(e) => setValues((prev) => ({ ...prev, team_distribution_finance: Number(e.target.value) }))}
            />
            <span>%</span>
          </div>

          <div className="flex justify-between items-center mt-3">
            <p>{t("lable.total")}</p> <div>{total} %</div>
          </div>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <Link to={`../form-offer`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default Human;
