import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiUsers } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { AiOutlineShop } from "react-icons/ai";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { LiaCoinsSolid } from "react-icons/lia";
import { PiPlant } from "react-icons/pi";
import { GoGear } from "react-icons/go";
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill } from "react-icons/bs";

import Human from "./Human";
import Offer from "./Offer";
import Market from "./Market";
import Strategy from "./Strategy";
import Innovation from "./Innovation";
import Impact from "./Impact";
import PaymentSuccess from "./PaymentSuccess";
import Situation from "./Situation";
import BusinessPlan from "./BusinessPlan";
import Finish from "./Finish";
import Documents from "./Documents";
import Interview from "./Interview";
import Product from "./Product";
import Result from "./Result";
import api from "@/services/api";
import Welcome from "./Welcome";

const SMEForm = () => {
  const { companyId } = useParams();
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${companyId}`);
        if (res.ok) {
          if (!res.data.valuation_has_paid && (pathname.includes("/form") || pathname.includes("/documents") || pathname.includes("/interview") || pathname.includes("/result")))
            navigation(`product`);
          else if (res.data.valuation_has_paid && pathname.includes("/product")) navigation(`form-human`);
          else {
            if (res.data.valuation_step === "FORM" && !pathname.includes("/form")) navigation(`form-human`);
            if (res.data.valuation_step === "DOCUMENTS" && !pathname.includes("/documents")) navigation(`documents`);
            if ((res.data.valuation_step === "INTERVIEW" || res.data.valuation_step === "INTERVIEW_SCHEDULED") && !pathname.includes("/interview")) navigation(`interview`);
            if (res.data.valuation_step === "REPORT" && !pathname.includes("/result")) navigation(`result`);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [companyId, location.pathname]);

  return (
    <div className="w-full flex">
      <Navigation />
      <section className="flex flex-col flex-1 items-center">
        <Routes>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/product" element={<Product />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/form-human" element={<Human />} />
          <Route path="/form-offer" element={<Offer />} />
          <Route path="/form-market" element={<Market />} />
          <Route path="/form-strategy" element={<Strategy />} />
          <Route path="/form-innovation" element={<Innovation />} />
          <Route path="/form-finance-situation" element={<Situation />} />
          <Route path="/form-finance-business-plan" element={<BusinessPlan />} />
          <Route path="/form-impact" element={<Impact />} />
          <Route path="/form-finish" element={<Finish />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/interview" element={<Interview />} />
          <Route path="/result" element={<Result />} />
        </Routes>
      </section>
    </div>
  );
};

const Navigation = () => {
  const [step, setStep] = useState("product");
  const [active, setActive] = useState("human");
  const location = useLocation();
  const { t } = useTranslation();
  const MENU = [
    {
      name: t("index.human"),
      link: "form-human",
      icon: <FiUsers />,
    },
    {
      name: t("index.offer"),
      link: "form-offer",
      icon: <MdOutlineLocalOffer />,
    },
    {
      name: t("index.market"),
      link: "form-market",
      icon: <AiOutlineShop />,
    },
    {
      name: t("index.strategy"),
      link: "form-strategy",
      icon: <HiOutlineRocketLaunch />,
    },
    {
      name: t("index.innovation"),
      link: "form-innovation",
      icon: <GoGear />,
    },
    {
      name: t("index.finance"),
      link: "form-finance",
      icon: <LiaCoinsSolid />,
      subMenu: [
        { name: "Situation", link: "form-finance-situation" },
        { name: "Business Plan", link: "form-finance-business-plan" },
      ],
    },
    {
      name: "Impact",
      link: "form-impact",
      icon: <PiPlant />,
    },
  ];

  useEffect(() => {
    setActive(location.pathname.split("/").pop());
    if (location.pathname.includes("product")) setStep("product");
    if (location.pathname.includes("form")) setStep("form");
    if (location.pathname.includes("documents")) setStep("documents");
    if (location.pathname.includes("interview")) setStep("interview");
    if (location.pathname.includes("result")) setStep("result");
  }, [location]);

  if (step === "product") return null;

  return (
    <ul className="space-y-6">
      <li>
        <div className={`flex items-center ${step === "form" ? "opacity-100" : "opacity-50"}`}>
          <Bs1CircleFill className="mr-3" />
          <h1 className="font-semibold">Formulaire</h1>
        </div>
      </li>
      {step === "form" &&
        MENU.map((item) => (
          <li key={item.name} className="pl-4">
            <NavLink menu={item} active={active} />
          </li>
        ))}
      <li>
        <div className={`flex items-center ${step === "documents" ? "opacity-100" : "opacity-50"}`}>
          <Bs2CircleFill className="mr-3" />
          <h1 className="font-semibold">Documents</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "interview" ? "opacity-100" : "opacity-50"}`}>
          <Bs3CircleFill className="mr-3" />
          <h1 className="font-semibold">Entretien</h1>
        </div>
      </li>
      <li>
        <div className={`flex items-center ${step === "result" ? "opacity-100" : "opacity-50"}`}>
          <Bs4CircleFill className="mr-3" />
          <h1 className="font-semibold">Résultat</h1>
        </div>
      </li>
    </ul>
  );
};

const NavLink = ({ menu, active }) => {
  const [open, setOpen] = useState(menu.subMenu ? menu.subMenu.some((subMenu) => subMenu.link === active) : false);

  useEffect(() => {
    if (menu.subMenu) setOpen(menu.subMenu.some((subMenu) => subMenu.link === active));
  }, [active]);

  if (menu.subMenu) {
    return (
      <div>
        <button className={`hover:text-primary hover:underline flex items-center ${open ? "text-primary" : ""}`} onClick={() => setOpen(!open)}>
          {menu.icon && <span className="mr-2">{menu.icon}</span>}
          {menu.name}
        </button>
        {open && (
          <ul className="ml-12 space-y-4 pt-4">
            {menu.subMenu.map((subMenu) => (
              <li key={subMenu.name}>
                <Link to={`${subMenu.link}`} className={`hover:text-primary hover:underline text-sm ${active === subMenu.link ? "text-primary underline" : ""}`}>
                  {subMenu.name}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
  return (
    <Link to={menu.link} className={`text-left flex items-center hover:text-primary hover:underline ${active === menu.link ? "text-primary underline" : ""}`}>
      {menu.icon && <span className="mr-2">{menu.icon}</span>}
      {menu.name}
    </Link>
  );
};

export default SMEForm;
