import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import SingleSelect from "../components/SingleSelect";

const MarketCharacteristics = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState();

  useEffect(() => {
    fetchQuestions();
    fetchData();
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const fetchQuestions = async () => {
    try {
      const res = await api.post("/question/search", { limit: 300, startup: true });
      if (res.ok) {
        setQuestions(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      // if (res.ok) {
      //   toast.success("Company updated successfully");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <SingleSelect question={questions?.find((q) => q.key === "entry_investment_requirements")} values={values} setValues={setValues} />

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.the_amount_already_made_to_enter_the_market")}
          <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-2 input justify-between items-center">
          <input
            className=" w-[90%] focus:outline-none "
            placeholder={t("placeholder_enter")}
            value={values?.entry_investment_amount}
            onChange={(e) => setValues((prev) => ({ ...prev, entry_investment_amount: e.target.value }))}
          />
          <span className="px-6 border-l"> € </span>
        </div>
      </div>

      <SingleSelect question={questions?.find((q) => q.key === "market_maturity")} values={values} setValues={setValues} />

      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {t("lable.does_ur_product_require_authorisations_to_enter_the_market")} <span className="ml-2 text-red-500">*</span>
        </label>
        <div className="flex gap-10 mt-3">
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="product_yes"
              name="has_product_authorization_needs"
              onChange={() => setValues((prev) => ({ ...prev, has_product_authorization_needs: true }))}
              checked={values?.has_product_authorization_needs === true}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="product_yes">
              {t("yes")}
            </label>
          </div>
          <div className="flex gap-4 items-center justify-center">
            <input
              type="radio"
              id="product_no"
              name="has_product_authorization_needs"
              onChange={() => setValues((prev) => ({ ...prev, has_product_authorization_needs: false }))}
              checked={values?.has_product_authorization_needs === false}
            />
            <label className="block text-gray-700 text-sm font-bold" htmlFor="product_no">
              {t("no")}
            </label>
          </div>
        </div>
      </div>

      {values.has_product_authorization_needs && (
        <>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("lable.indicate_ur_authorities")}
              <span className="ml-2 text-red-500">*</span>
            </label>
            <input
              className="input"
              placeholder={t("placeholder_enter")}
              value={values?.authorities || ""}
              onChange={(e) => setValues((prev) => ({ ...prev, authorities: e.target.value }))}
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("lable.have_you_obtained_auth")} <span className="ml-2 text-red-500">*</span>
            </label>
            <div className="flex gap-10 mt-3">
              <div className="flex gap-4 items-center justify-center">
                <input
                  type="radio"
                  id="auth_yes"
                  name="obtained_authorizations"
                  onChange={() => setValues((prev) => ({ ...prev, obtained_authorizations: true }))}
                  checked={values?.obtained_authorizations === true}
                />
                <label className="block text-gray-700 text-sm font-bold" htmlFor="auth_yes">
                  {t("yes")}
                </label>
              </div>
              <div className="flex gap-4 items-center justify-center">
                <input
                  type="radio"
                  id="auth_no"
                  name="obtained_authorizations"
                  onChange={() => setValues((prev) => ({ ...prev, obtained_authorizations: false }))}
                  checked={values?.obtained_authorizations === false}
                />
                <label className="block text-gray-700 text-sm font-bold" htmlFor="auth_no">
                  {t("no")}
                </label>
              </div>
            </div>
          </div>
          {!values.obtained_authorizations && (
            <>
              <SingleSelect question={questions?.find((q) => q.key === "refusal_rate")} values={values} setValues={setValues} />
              <SingleSelect question={questions?.find((q) => q.key === "authorization_timeline")} values={values} setValues={setValues} />{" "}
            </>
          )}
        </>
      )}

      <SingleSelect question={questions?.find((q) => q.key === "network_effects_presence")} values={values} setValues={setValues} />

      <SingleSelect question={questions?.find((q) => q.key === "special_features_demand_intensity")} values={values} setValues={setValues} />

      <SingleSelect question={questions?.find((q) => q.key === "market_fit")} values={values} setValues={setValues} />

      <div className="flex justify-between w-full">
        <Link to={`../form-market-position`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <Link to={`../form-market-competitors`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default MarketCharacteristics;
