import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoWalletOutline, IoChatbubbleOutline } from "react-icons/io5";
import { RiHome6Line, RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { IoIosSearch } from "react-icons/io";
import { PiChartPieSlice } from "react-icons/pi";
import { LuListTodo, LuFolder } from "react-icons/lu";
import { FaRegListAlt } from "react-icons/fa";
import { TbShoppingBagSearch } from "react-icons/tb";

import LogoPNG from "@/assets/img/logo.png";

const MENU = [
  { title: "Accueil", to: "/home", logo: <RiHome6Line className="h-5 w-5" /> },
  { title: "Évaluation", to: "/valuation", logo: <PiChartPieSlice className="h-5 w-5" /> },
  { title: "Investisseurs", to: "/investisseurs", logo: <IoWalletOutline className="h-5 w-5" /> },
  { title: "Acquéreurs", to: "/buyers", logo: <TbShoppingBagSearch className="h-5 w-5" /> },
  { title: "Annonces", to: "/annonce", logo: <IoIosSearch className="h-5 w-5" /> },
  { title: "Discussions", to: "/discussion", logo: <IoChatbubbleOutline className="h-5 w-5" /> },
  { title: "Mes annonces", to: "/my-annonce", logo: <LuListTodo className="h-5 w-5" /> },
  //{ title: "Data Room", to: "/data-room", logo: <LuFolder className="h-5 w-5" /> },
];

const Navbar = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="h-screen bg-white border-r border-secondary">
      <div className="flex flex-col gap-5 items-center justify-between p-5">
        <div className="relative w-2/3">
          <img className="mb-5" alt="logo" src={LogoPNG} />
          <span className="absolute right-0 bottom-0 text-primary font-bold text-sm rounded-full p-1">beta</span>
        </div>
        {MENU.map((menu, index) => (
          <Link to={menu.to} key={menu.title} className="flex flex-col justify-center items-center" onClick={() => setSelected(index)}>
            <div className={`${selected === index ? "bg-gray-100" : ""} rounded-full flex justify-center items-center w-10 h-10`}>{menu.logo}</div>
            <p className={`text-xs text-center`}>{menu.title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
