import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import Loader from "@/components/Loader";
import api from "@/services/api";
import useStore from "@/services/store";

const DISCUSSION_STATUS = {
  REFUSED: <span className="text-red-500 px-3 text-xs border border-red-500 p-1 rounded text-center">Refusée</span>,
  PENDING: <p className="text-xs text-yellow-600 border border-yellow-500 p-1 rounded text-center">En attente</p>,
  ACCEPTED: <p className="text-xs text-yellow-600 border border-yellow-500 p-1 rounded text-center">En attente</p>,
  ONGOING: <p className="text-xs text-green-500 border border-green-500 p-1 rounded text-center">En cours</p>,
  STOPPED: <p className="text-xs text-red-500 border border-red-500 p-1 rounded text-center">Arrêtée</p>,
};

const List = () => {
  const [loading, setLoading] = useState(false);
  const [discussions, setDiscussion] = useState([]);
  const { user } = useStore();

  useEffect(() => {
    fetchDiscussions();
  }, []);

  const fetchDiscussions = async () => {
    setLoading(true);
    try {
      const res = await api.post("/discussion/search", { user_id: user._id });
      if (res.ok) {
        const data = res.data;
        setDiscussion(data);
      }
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  return (
    <div className="p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Mises en relation en cours</h1>
        <p className="text-base text-gray-500">Retrouvez toutes vos demandes de mises en relation en cours</p>
      </header>

      <div className="grid grid-cols-3 gap-4 mt-10">
        {loading ? (
          <div className="flex justify-center items-center col-span-4">
            <Loader />
          </div>
        ) : discussions.length > 0 ? (
          discussions.map((item, index) => <Card key={index} data={item} />)
        ) : (
          <p className="text-center col-span-4">Aucune mise en relation en cours</p>
        )}
      </div>
    </div>
  );
};

const Card = ({ data }) => {
  const { user } = useStore();

  return (
    <Link to={`/discussion/${data._id}`} className="border border-secondary rounded-xl p-6 space-y-4 hover:border-primary">
      {DISCUSSION_STATUS[data.status]}
      {data.user_to_id == user._id ? (
        <div className="flex items-center">
          <p className="ml-4">
            {data.user_from_first_name} {data.user_from_last_name}
          </p>
        </div>
      ) : data.investor_id ? (
        <div className="flex items-center">
          <p className="ml-4">{data.investor_name}</p>
        </div>
      ) : (
        <div className="flex items-center">
          <p className="ml-4">{data.annonce_title}</p>
        </div>
      )}

      <div>
        {data.nda_user_to_signed && data.nda_user_from_signed ? (
          <span className="border border-secondary text-gray-500 rounded p-1 text-xs px-3">NDA signé par les deux parties</span>
        ) : data.nda_user_from_signed ? (
          <span className="border border-secondary text-gray-500 rounded p-1 text-xs px-3">NDA signé par l'investisseur</span>
        ) : data.nda_user_to_signed ? (
          <span className="border border-secondary text-gray-500  px-3 rounded p-1 text-xs">NDA signé par l'entrepreneur</span>
        ) : data.status === "ONGOING" && !data.nda_user_to_signed && !data.nda_user_from_signed ? (
          <span className="border border-secondary text-gray-500  px-3 rounded p-1 text-xs">Action requise</span>
        ) : null}
      </div>
    </Link>
  );
};

export default List;
