import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import api from "@/services/api";
import toast from "react-hot-toast";
import Loader from "@/components/Loader";
import SingleSelect from "../components/SingleSelect";
import MultiSelect from "../components/MultiSelect";

const StrategyGrowth = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const [questions, setQuestions] = useState();

  useEffect(() => {
    fetchQuestions();
    fetchData();
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChange();
    }, 400);
    return () => clearTimeout(timeout);
  }, [values]);

  const fetchQuestions = async () => {
    try {
      const res = await api.post("/question/search", { limit: 300, startup: true });
      if (res.ok) {
        setQuestions(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await api.get(`/company/${id}`);
      if (res.ok) {
        if (!res.data.plan) res.data.plan = "certified";
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async () => {
    try {
      const res = await api.put(`/company/${id}`, values);
      // if (res.ok) {
      //   toast.success("Company updated successfully");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <SingleSelect question={questions?.find((q) => q.key === "crucial_activities_internalised")} values={values} setValues={setValues} />

      <MultiSelect question={questions?.find((q) => q.key === "exit_opportunities")} values={values} setValues={setValues} />

      {values.exit_opportunities != "Pas de sortie (Croissance)" && (
        <SingleSelect question={questions?.find((q) => q.key === "expected_exit_time")} values={values} setValues={setValues} />
      )}

      <div className="flex justify-between w-full">
        <Link to={`../form-strategy-communication`} className="empty-button disabled:hover:bg-white px-10">
          {t("previous")}
        </Link>
        <Link to={`../form-innovation-state-of-the-art`} className="primary-button disabled:hover:bg-white px-10">
          {t("next")}
        </Link>
      </div>
    </div>
  );
};

export default StrategyGrowth;
