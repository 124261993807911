import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { CALENDLY_ANALYSE, CALENDLY_ANALYSE_NOTATION } from "../../../services/config";
import api from "@/services/api";
import Loader from "../../../components/Loader";
import Company from "@/pages/annonce/create/Company";

const Interview = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [values, setValues] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setValues(res.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleNext = async () => {
    try {
      const res = await api.put(`/company/${id}`, { valuation_step: "INTERVIEW_SCHEDULED" });
      if (res.ok) {
        navigate(`../result`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!values) return <Loader />;

  if (values.valuation_step === "INTERVIEW_SCHEDULED") return <WaitingInterview values={values} setValues={setValues} />;

  return (
    <div className="w-full space-y-8 px-40">
      <h1 className="font-semibold text-xl"> {t("interview.title")}</h1>
      <p className="text-base text-gray-500"> {t("interview.text")}</p>
      <p className="text-gray-500">
        <div className="flex items-center gap-4 mt-8">
          {isLoading && (
            <div className="w-full h-full flex items-center justify-center">
              <Loader />
            </div>
          )}
          {values.valuation_plan === "NOTATION" ? (
            <iframe
              style={{ display: isLoading ? "none" : "block" }}
              src={CALENDLY_ANALYSE_NOTATION}
              width="100%"
              height="900"
              frameborder="0"
              onLoad={() => setIsLoading(false)}
              allowfullscreen
            ></iframe>
          ) : (
            <iframe
              style={{ display: isLoading ? "none" : "block" }}
              src={CALENDLY_ANALYSE}
              width="100%"
              height="900"
              frameborder="0"
              onLoad={() => setIsLoading(false)}
              allowfullscreen
            ></iframe>
          )}
        </div>
      </p>
      <div className="flex justify-end w-full ">
        <button type="button" className="primary-button" onClick={handleNext}>
          J'ai pris mon rendez-vous
        </button>
      </div>
    </div>
  );
};

const WaitingInterview = ({ values, setValues }) => {
  const handleBookAgain = async () => {
    try {
      const res = await api.put(`/company/${values._id}`, { valuation_step: "INTERVIEW" });
      if (res.ok) {
        setValues(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full space-y-8 px-40 flex flex-col items-center">
      <h1 className="font-semibold text-xl">En attente de l'entretien</h1>
      <p className="text-base text-gray-500 text-center">
        Vous avez pris rendez-vous pour l'entretien. Lorsque l'entretien sera terminé, vous pourrez accéder aux résultats de la valorisation.
      </p>
      <button type="button" className="primary-button" onClick={handleBookAgain}>
        Reprendre un rendez-vous
      </button>
    </div>
  );
};

export default Interview;
